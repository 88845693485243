<template>

  <div class="mt-15">

  

    <v-container fluid>

      <v-row>
        <v-col>
          <v-card :max-width="intCardWidth" height=650>
            <a href="./albania">
            <v-img
              height="250"
              src="../../public/img/projects/albania/nun.jpg"
            ></v-img>
            </a>
            <v-card-title
              style="color: #283583"
            >Albanien</v-card-title>
            <v-card-text style="font-size: 15px; color: black">
              Albanien verfügt über ertragreiche Land- und Forstwirtschaft. Im Tourismus steckt großes Entwicklungspotential. Doch durch die überall herrschende Korruption wird eine nachhaltige Entwicklung des Landes zunichte gemacht. Um den Ärmsten zu helfen und der steigenden Auswanderung entgegenzuwirken, engagiert sich der Hilfsverein bei verschiedenen Sozialprojekten.
              <br><br>
              <v-btn 
                @click="CleverRoute('albania')"
                color="primary darken-2"
              >
                Lesen Sie mehr...
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card :max-width="intCardWidth" height=650>
            <a href="./kenia">
            <v-img
              height="250"
              src="../../public/img/projects/kenya/kids.jpg"
            ></v-img>
            </a>
            <v-card-title
              style="color: #283583"
            >Kenia</v-card-title>
            <v-card-text style="font-size: 15px; color: black">
              Bildung ist auf vielerlei Weise eine der nachhaltigsten Entwicklungshilfen. Sie fördert die Aufklärung und kann Frieden stiften. Der Hilfsverein bringt Kinder verfeindeter Volksstämme friedlich in der Schule zusammen und ermöglicht Mädchen in Internaten den sicheren Zugang zu Bildung und einer Zukunft. Die junge Generation hat so die Chance das Land aufzubauen. Der fortschreitende Brunnenbau hilft über die Dürreperioden hinweg und rettet die Menschen vor dem Hungertod.
              <br><br>
              <v-btn 
                @click="CleverRoute('kenia')"
                color="primary darken-2"
              >
                Lesen Sie mehr...
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card :max-width="intCardWidth" height=650>
            <a href="./romania">
            <v-img
              height="250"
              
              src="../../public/img/projects/romania/rodicia.jpg"
            ></v-img>
            </a>
            <v-card-title
              style="color: #283583"
            >Rumänien</v-card-title>
            <v-card-text style="font-size: 15px; color: black">
              In Rumänien herrscht in vielen Teilen der Bevölkerung, insbesondere innerhalb der Volksgruppe der Roma, große Armut. Alte Menschen leiden unter der mangelhaften Grundversorgung mit Lebensmitteln und Medikamenten. Kinder und Jugendliche aus verarmten Familien sind oft Opfer von kriminellen Vereinigungen und Menschenhandel. Sie haben kaum eine Chance, später einen Arbeitsplatz in ihrem Land zu finden.
              <br><br>
              <v-btn 
                @click="CleverRoute('romania')"
                color="primary darken-2"
              >
                Lesen Sie mehr...
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card :max-width="intCardWidth" height=650>
            <a href="./ukraine">
            <v-img
              height="250"
              
              src="../../public/img/projects/ucraine/refugees.jpg"
            ></v-img>
            </a>
            <v-card-title
              style="color: #283583"
            >Ukraine</v-card-title>
            <v-card-text style="font-size: 15px; color: black">
              Auf den völkerrechtswidrigen Überfall Russlands auf die Ukraine war niemand ausreichend vorbereitet. Umso wichtiger war es zu Beginn des Krieges den im Nachbarland Rumänien ankommenden Geflüchteten schnellstmöglich zu helfen, was wir durch unser bestehendes Partnernetzwerk in Ostrumänien effizient vor Ort tun konnten. Seitdem haben wir dort an der Grenze zur Ukraine eine feste Präsenz eingerichtet, von der aus wir Hilfsgütertransporte starten.<br><br>
              <v-btn 
                @click="CleverRoute('ucraine')"
                color="primary darken-2"
              >
                Lesen Sie mehr...
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
        
       
      </v-row>

    </v-container>
   

  </div>
</template>


<script>

export default {
  props: {
    visitedCountries: {},
  },
  computed: {
    intCardWidth () {
            switch (this.$vuetify.breakpoint.name) {
            case 'xs': return 350
            case 'sm': return 350
            case 'md': return 350
            case 'lg': return 350
            case 'xl': return 400
            default: return 350

            }
        },
  },
  data() {
    return {
      map: {
        defaultCountryFillColor: "blue",
        highColor: "red",
        lowColor: "white",
        countryStrokeColor: "#1960e3",
      },
    };
  },
  methods: {
    CleverRoute: function (strRoute) {
      if (!window.location.href.includes("reset")) {
        this.$router.push({ path: strRoute });
      } else {
        this.$router.push({ path: "." + strRoute });
      }
    },
  }
};
</script>

<style scoped>

</style>
