<template>
  <div class="mt-15">
    
    <v-layout align-center justify-center>

      <v-card flat min-width="300" max-width="660" class="px-8">

      <div class="mx-auto mb-5 parallax-title" :style="TitleSize">
      Spenden an den Hilfsverein
      </div><br>
      Wir danken für jede Spende! Gerne können Sie auch im Betreff ein Projekt, eine Region oder eine Veranstaltung angeben. Ihre Spende wird dann entsprechend zugeordnet. 
      <br><br>
          <div style="text-align: center;">
            <div style="display: inline-block; text-align: left;"> 
              <b>Konto Daten:</b><br>  
              Bank:   Hypo Vereinsbank München <br>
              Konto:  154 088 11 <br>
              BLZ:    700 202 70 <br>
              IBAN:   DE74 7002 0270 0015 4088 11 <br>
              BIC:    HYVEDEMMXXX <br>
            </div>
        </div>
        <br>
        Wenn Sie eine Spendenquittung wünschen, geben Sie bitte Ihre Adresse im Betreff der Überweisung an. 
        <br>
        <br>
        Oder spenden Sie direkt auf <b>Paypal</b>
        <Donation :str-dedication="strDedication"/>
      </v-card>

      

    </v-layout>
  </div>
</template>



<script>
import Donation from "../components/Donation";

export default {
  name: 'Home',
  props: {
        strDedication: {
            // "Input" "Display" used to determine if Component is used to display data or collect or update data.
            type: String,
            default: "Freie Spende"
    }
  },
  components: {
    Donation
  },
  computed: {
    TitleSize () {
            switch (this.$vuetify.breakpoint.name) {
            case 'xs': return 'font-size:20px'
            case 'sm': return 'font-size:25px'
            case 'md': return 'font-size:30px'
            case 'lg': return 'font-size:40px;'
            case 'xl': return 'font-size:50px;'
            default: return 'font-size:25px'

            }
        },

  }
}
</script>
