<template>
<div>

        <v-btn
          class="mx-4"
          dark
          icon
          @click.stop="blnEmailPop = true"
        ><v-icon size="24px">mdi-email</v-icon></v-btn>

        
        <v-dialog
            v-model="blnCookieWarn"
            max-width="290"
        >
            <v-card>
                <v-img class="mx-auto" max-width="150" src="../../public/img/cookie.png"></v-img>
                <v-card-text>
                    
                    Diese Webseite verwended Cookies

                    Näheres findest Du in der Datenschutzbestimmung im Impressum.
                    
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="primary darken-2"
                        @click="NoticeCookie"
                        class="mx-auto"
                    >
                        Cookies nehm ich gern!
                    </v-btn>
                </v-card-actions>
                
            </v-card>
        </v-dialog>


    
</div>
</template>

<script>

  export default {
    data: () => ({
        blnCookieWarn: false
    }),
    mounted: function(){

        this.CheckCookie();
    },
    methods:{

        NoticeCookie: function() {
            //console.log("inNotice")
            this.SetCookie("hilfsverein", "lecker_cookie", 30);
            this.blnCookieWarn = false;
        },

        CheckCookie: function() {
            var hilfsverein = this.GetCookie("hilfsverein");
            if (hilfsverein == "lecker_cookie") {
                this.blnCookieWarn = false;
            } else {
                
                this.blnCookieWarn = true;
                
            }
        },

        SetCookie: function(strName, jwtValue, intExpiryDays) {
        
                var d = new Date();
                d.setTime(d.getTime() + (intExpiryDays*24*60*60*1000));
                var expires = "expires="+ d.toUTCString();
                document.cookie = strName + "=" + jwtValue + ";" + expires + ";path=/";
        
            }, // End Set Cookie 
        
        
            GetCookie: function getCookie(strName){
            var name = strName + "=";
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(';');
            for(var i = 0; i <ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' '){
                    c = c.substring(1);
                }
        
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        
            }, // Ed Get CookieS
    }
  }
</script>
