<template>
<div>
    <Cookie></Cookie>
  <v-footer
    dark
    padless
  >
    <v-card
      class="flex"
      flat
      tile
    >
      <v-card-title class="primary darken-3">
        
        <v-spacer></v-spacer>

        
        <!-- <v-btn
          class="mx-4"
          dark
          icon
          href="https://www.facebook.com/L%C3%B6wenmarsch-124222895875419"
          target="_blank"
        ><v-icon size="24px">mdi-facebook</v-icon></v-btn>
   
        <v-btn
          class="mx-4"
          dark
          icon
          href="https://www.instagram.com/learning_lions/?hl=en"
          target="_blank"
        ><v-icon size="24px">mdi-instagram</v-icon></v-btn> -->
        <v-btn
          class="mx-4"
          dark
          icon
          @click.stop="blnEmailPop = true"
        ><v-icon size="24px">mdi-email</v-icon></v-btn>

        
        <v-dialog
            v-model="blnEmailPop"
            max-width="290"
        >
            <v-card>
                <v-card-text>Bitte schreibt uns:
                    <br><a href="mailto:hilfsverein-nymphenburg.de"><b>info@hilfsverein-nymphenburg.de</b></a>
                </v-card-text>

                
            </v-card>
        </v-dialog>

      </v-card-title>

      <v-card-text class="py-2 white--text text-center">
          
        {{ new Date().getFullYear() }} — <strong>Hilfsverein Nymphenburg </strong>
        <Impressum style="float:right"></Impressum>
      </v-card-text>
      

    </v-card>
  </v-footer>

</div>
</template>

<script>

import Impressum from './Impressum';
import Cookie from './Cookie';

  export default {
    data: () => ({
        blnEmailPop: false,
      icons: [
        'mdi-facebook',
        'mdi-twitter',
        'mdi-linkedin',
        'mdi-instagram',
      ],
      dialog: false
    }),
    components:{
        Impressum,
        Cookie
    }
  }
</script>
