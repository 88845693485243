import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Donate from '../views/Donate.vue'
import Information from '../views/Information.vue'
import Projects from '../views/Projects.vue'
import Albania from '../views/RegionAlbania.vue'
import Kenia from '../views/RegionKenia.vue'
import Romania from '../views/RegionRomania.vue'
import Ukraine from '../views/RegionUkraine.vue'
// import Items from '../views/Items.vue'
import Thanks from '../views/Thanks.vue'
import Blog from '../views/Blog.vue'
import Events from '../views/Events.vue'
import Cancelled from '../views/Cancelled.vue'
import AboutStatutesAssociation from '../views/AboutStatutesAssociation.vue'
import AboutStatutesFoundation from '../views/AboutStatutesFoundation.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Donate',
    component: Home
  },
  {
    path: '/donate',
    name: 'Donate',
    component: Donate
  },
  {
    path: '/donate/dedication/:strDedication',
    name: 'Donate',
    component: Donate,
    props: true
  },
  {
    path: '/blog',
    name: 'Blog',
    component: Blog
  },
  {
    path: '/events',
    name: 'Events',
    component: Events
  },
  {
    path: '/information',
    name: 'Information',
    component: Information
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects
  },
  {
    path: '/albania',
    name: 'Albania',
    component: Albania
  },
  {
    path: '/kenia',
    name: 'Kenia',
    component: Kenia
  },
  {
    path: '/romania',
    name: 'Romania',
    component: Romania
  },
  {
    path: '/ucraine',
    name: 'Ucraine',
    component: Ukraine
  },
  // {
  //   path: '/items',
  //   name: 'Items',
  //   component: Items
  // },
  {
    path: '/thanks',
    name: 'Thanks',
    component: Thanks
  },

  {
    path: '/cancelled',
    name: 'Cancelled',
    component: Cancelled
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },

  {
    path: '/about/statutes-association',
    name: 'StatutesAssociation',
    component: AboutStatutesAssociation
  },

  {
    path: '/about/statutes-foundation',
    name: 'StatutesFoundation',
    component: AboutStatutesFoundation
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
