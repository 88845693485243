<template>
  <div class="mt-15">
    
    <v-layout align-center justify-center>

      <v-card flat min-width="300" max-width="660" class="px-8">

      <div class="mx-auto mb-5 parallax-title" :style="TitleSize">
      Herzlichen Dank für Ihre Spende!
      </div><br>
      <div class="mx-auto mb-5">
      <img src='../../public/img/heart.png' :width="HeartSize">
      </div>
      Bei Spenden <b>über 200 EUR</b> erhalten Sie von uns automatisch eine <b>Spendenquittung</b>, sofern Ihre Kontaktdaten korrekt auf Paypal hinterlegt waren. <br><br>
      
      Für Spenden unter 200 EUR genügt ein Auszug aus Ihrem Paypal Konto um die Spende bei Ihrer Steuererklärung geltend zu machen. Diesen erhalten Sie je nach Ihren individuellen Paypal Einstellungen per Email oder indem Sie sich auf Ihr Paypal Konto einloggen. <br><br>
      Wenn Sie für eine Spende unter 200 EUR dennoch eine Spendenquittung benötigen, schreiben Sie uns bitte unter <a href="mailto:info@hilfsverein-nymphenburg.de">info@hilfsverein-nymphenburg.de</a>
      <br><br>
      Da uns von Paypal nur die notwendigsten Daten mitegeteilt werden, erhalten Sie vom Hilfsverein Nymphenburg <b>kein gesondertes Email</b>, Die Spende wird aber trozdem verbucht und zugeordnet. 
      </v-card>

      

    </v-layout>
  </div>
</template>



<script>
import Donation from "../components/Donation";

export default {
  name: 'Thanks',
  components: {
    Donation
  },
  computed: {
    TitleSize () {
            switch (this.$vuetify.breakpoint.name) {
            case 'xs': return 'font-size:20px'
            case 'sm': return 'font-size:25px'
            case 'md': return 'font-size:30px'
            case 'lg': return 'font-size:40px;'
            case 'xl': return 'font-size:50px;'
            default: return 'font-size:25px'

            }
        },
    HeartSize () {
            switch (this.$vuetify.breakpoint.name) {
            case 'xs': return '300px'
            case 'sm': return '400px'
            case 'md': return '500px'
            case 'lg': return '600px;'
            case 'xl': return '600px;'
            default: return '25px'

            }
        },

  }
}
</script>
