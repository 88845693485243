<template>
  <nav>
    <v-app-bar flat fixed color="white">
        
        <v-toolbar-title class="text-uppercase grey--text" >
          
          <v-row>
              <v-col cols="1">
                  <a href="./">
                    <img src="../../public/img/wappen.png" width="20px">
                  </a>
                </v-col>
              
              <v-col>
                <span class="font-weight-light" :style="TitleSize">Hilfsverein Nymphenburg</span>
              </v-col>
            </v-row>
          
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <div v-if="blnMenu" >
            <v-tabs   right>
              <v-tab @click="(CleverRoute('./'))">Home</v-tab>
              <!-- <v-tab @click="(CleverRoute('git status
              ./information'))">Informationen</v-tab> -->
              <v-tab @click="(CleverRoute('projects'))">Projekte</v-tab>
              <v-tab @click="(CleverRoute('about'))">Über Uns</v-tab>
              <!-- <v-tab @click="(CleverRoute('blog'))">Blog</v-tab> -->
              <v-tab @click="(CleverRoute('events'))">Events</v-tab>
              <v-tab style="background-color: #283583 ; color: white" @click="(CleverRoute('donate'))">Spenden</v-tab>
              <!-- <v-tab @click="(CleverRoute('./items'))">Sachspenden</v-tab> -->
              
          </v-tabs>
        </div>
        
        <div v-if="!blnMenu" >
          
              <v-menu right>
                <template v-slot:activator="{ on, attrs }" right>
                  
                  <v-btn icon v-bind="attrs" v-on="on" >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
            
                <v-list>
                      
                        <v-list-item @click="(CleverRoute('./'))">
                            <v-list-item-title>
                              HOME</v-list-item-title>
                        </v-list-item>
                        <!-- <v-list-item @click="(CleverRoute('./information'))">
                            <v-list-item-title>
                            Informationen</v-list-item-title>
                        </v-list-item> -->
                        <v-list-item @click="(CleverRoute('donate'))">
                            <v-list-item-title>
                              Geld Spenden</v-list-item-title>
                        </v-list-item>
                        <v-tab @click="(CleverRoute('projects'))">Projekte</v-tab>
                        <!-- <v-list-item @click="(CleverRoute('./items'))">
                            <v-list-item-title>
                              Sachspenden</v-list-item-title>
                        </v-list-item> -->
                        
                    </v-list>
              </v-menu>
           
        </div>
        
     

      <v-spacer></v-spacer>

      <!-- <v-btn color="green lighten-2" @click="LogButton">
        <span>{{ strLogButton }}</span>
        <v-icon right>mdi-exit-to-app</v-icon>
      </v-btn> -->
    </v-app-bar>
  </nav>
</template>

<script>
//import LoginUser from './LoginUser'
import { mapGetters } from "vuex";
import { mapMutations } from "vuex";

export default {
  components: {
    //LoginUser
  },
  data() {
    return {
      //strLogButton: "Login"
    };
  },
  computed: {
    ...mapGetters(["getIdUser", "getBlnIsAdmin"]),

    strLogButton: function () {
      if (this.getIdUser > 0) {
        return "logout";
      } else {
        return "login";
      }
    },
    TitleSize () {
            switch (this.$vuetify.breakpoint.name) {
            case 'xs': return 'font-family:Charlemagne; font-size:15px;'
            case 'sm': return 'font-family:Charlemagne; font-size:18px;'
            case 'md': return 'font-family:Charlemagne; font-size:20px;'
            case 'lg': return 'font-family:Charlemagne; font-size:25px;'
            case 'xl': return 'font-family:Charlemagne; font-size:30px;'
            default: return 'font-size:20px'
            }
    },
    blnMenu () {
            switch (this.$vuetify.breakpoint.name) {
            case 'xs': return false
            case 'sm': return false
            case 'md': return true
            case 'lg': return true
            case 'xl': return true
            default: return false
            }
    },
    
  },
  methods: {
    ...mapMutations(["setIdUser"]),

    LogButton: function () {
      if (this.strLogButton == "logout") {
        //console.log("in Lougout")
        this.setIdUser(0);
        this.CleverRoute("./anmelden");
      } else {
        this.CleverRoute("./anmelden");
      }
    },

    CleverRoute: function (strRoute) {
      if (!window.location.href.includes("dedication")) {
        this.$router.push({ path: strRoute });
      } else {
        this.$router.push({ path: "../../" + strRoute });
      }
    },

    ShowLogin: function () {
      //console.log("emit show Login Dialog")
      this.$emit("showlogin");
    },
  },
};
</script>

<style></style>
