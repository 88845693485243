<template>

  <div class="mt-15">
     
<v-img
 :height="ImageHeight"
 src="../../public/img/projects/ucraine/ucraine_title.jpg"
>
 <div style="
   margin: 0;
   position: absolute;
   top: 50%; 
   left: 50%; 
   font-size: 60px; 
   font-weight: bold; 
   color: blue;" >
   UKRAINE
 </div>
</v-img>

<v-layout align-center justify-center>

   <v-card flat min-width="300" max-width="1200" class="px-8 pt-10">
     
    <br><br>
    Der Hilfsverein Nymphenburg hat schon seit vielen Jahren Projekte in Bukowina, der Grenzregion Rumäniens - zwischen Ukraine und Moldawien. Unmittelbar nach Kriegsbeginn haben wir dort eine ständige Präsenz eingerichtet und können in Zusammenarbeit mit einem lokalen Landwirtschaftsbetrieb der Familie Nesselrode unmittelbar am Grenzposten Hilfsgüter lagern und von hier aus koordinieren. Wir stehen in engem Austausch mit dem rumänischen Roten Kreuz und Malteser International. Außerdem versuchen wir gemeinsam mit örtlichen Gemeinden und der Kirche den Geflüchteten in Rumänien zu helfen.
    <br><br>
                  <img src="../../public/img/map.png" :width="MapSize" class="section-title" />
                  <br><br>
          
    <div v-for="objEvent in arrEvent" :key="objEvent.strTitle">
         <Project
           :str-title="objEvent.strTitle"
           :txt-body="objEvent.txtBody"
           :str-img-path="objEvent.strImgPath"

         ></Project>
   </div>
   
   </v-card>

</v-layout>

 
   
 </div>
</template>

<script>

import Project from '../components/Project'; 

export default {
   
   components:{
     Project
   },
   data: function(){
       return{

           blnCampaign: false,
           arrEvent: [
           
            {
              strTitle: "Nothilfe-Aktion",
                txtBody:
                `
                Nach dem Angriff auf die Ukraine begann wir kurzfristig eine Nothilfe-Aktion, riefen im März erstmals seit vielen Jahren zu einer Sonder-Spende auf und nahmen Sachspenden entgegen. Dank vieler großzügiger Spender und fleißiger Helfer konnten wir so im kalten Frühjahr 2022 warme Winterkleidung und ganze Paletten von Lebensmitteln und anderen Bedarfsartikeln in die Ukraine bringen.Durch einen glücklichen Zufall wurde dem Hilfsverein Nymphenburg eine Lagerhalle unmittelbar am Grenzposten Siret zwischen Rumänien und der Ukraine zur Verfügung gestellt. Somit konnten wir bereits Anfang März Hilfsgüter dorthin bringen und in ukrainische Fahrzeuge umladen. Als Partner wurde der rumänische Rot Kreuz-Verband Focsan gewählt, mit dem der Hilfsverein seit Jahren zusammenarbeitet. Außerdem haben wir sofort Lebensmittel und Hygieneartikel an Flüchtende innerhalb der Ukraine geschickt. Seit März 2022 unterhält der Hilfsverein Nymphenburg in Siret eine ständige Präsenz. Über eine Versorgungsroute werden von dort aus humanitäre Güter dorthin in die Ukraine gebracht, wo sie am dringendsten benötigt werden. So konnten wir uns im vergangenen Jahr immer wieder spontan auf den Bedarf vor Ort einstellen.    
                `,
                strImgPath: "ucraine/nothilfe.jpg"
              },
              
              {
                strTitle: "Hilfe für Geflüchtete in der Ukraine",
                txtBody:
                `
                Zu Beginn des Krieges richtete der Hilfsverein seine Aktionen an die innerhalb der Ukraine Geflüchteten, bei denen die Hilfsgüter der großen Organisationen oftmals verspätet ankamen. Allein in der Stadt Czernowitz waren Mitte Mai rund 80.000 Frauen und Kinder untergebracht. Um diese zu versorgen, finanzierte der Hilfsverein mit der METRO Rumänien mehrere Lastzüge voller Lebensmittel und Bedarfsartikel.  
Besonders wichtig war es auch, Medikamente und chirurgisches Material in die Ukraine zu bringen. Diese wurden anfangs in Rumänien bezogen, da es in den ersten Wochen schwer war, aufgrund des europäischen Arzneimittelgesetzes Medikamente über europäische Grenzen zu verschicken. Es ergab sich eine gute Zusammenarbeit mit deutschen Ärzten über die Stiftung “Hilfe zur Selbsthilfe”.   
                `,
                strImgPath: "ucraine/arzt.jpg"
              },
              {
                strTitle: "Survival-Kits",
                txtBody:
                `
                Nachdem die großen internationalen Organisationen die Versorgung der Binnenflüchtlinge übernommen hatten, lag der Fokus des Hilfsverein Nymphenburg darauf Survival-Kits für Menschen in den umkämpften Gebieten zusammenzustellen und dorthin zu liefern, wo sie gebraucht wurden. Diese bestanden aus einer (Einkaufs-) Tasche mit Rollen, um Versorgungsgüter von den Sammelpunkten abholen zu können, was besonders für ältere Menschen hilfreich ist. Außerdem enthält die Tasche einen hochwertigen, langlebigen Wasserfilter, und nützliche Dinge, wie einen Verbandskasten, ein Multitool, ein kleiner Kohleofen als Notherd sowie ein kleiner Stromgeneratoren mit einer Handkurbel, als Lampe oder zum Laden von Handys.  
Ziel der Survival-Kits sind Gebiete mit zerstörter Infrastruktur, besonders vormals besetzte Regionen, die wieder befreit sind. Für den ersten Konvoi der Survival-Kit-Aktion im Herbst wurde die Region zwischen der Stadt Mikolaiev und der noch immer umkämpften Stadt Cherson ausgewählt. Der Konvoi wurde vom Roten Kreuz Focsan in Zusammenarbeit und in Begleitung von Freiwilligen des Hilfsvereins Nymphenburg durchgeführt. Mit Genehmigung des ukrainischen Militärs konnten bis auf 20 km von der Front noch Pakete ausgegeben werden.  
                `,
                strImgPath: "ucraine/kit.jpg"
             },
              {
                strTitle: "Wiederaufbau-Kits",
                txtBody:
                `
                Zurzeit unterstützt der Hilfsverein Nymphenburg die Menschen in der Ukraine dabei, provisorische Reparaturen an ihren Häusern durchführen zu können. Dafür liefern wir hauptsächlich Baumaterialien und Werkzeug als Wiederaufbau-Kits in die Ukraine, unter anderem bestehend aus  großen Spanplatten und Baubedarf, wie Sägen und andere benötigte Werkzeuge. Aber auch Schlafsäcke und Isomatten bieten wir an. 
                `,
                strImgPath: "ucraine/wiederaufbau.jpg"
              },

         ]

       }

   }
   
}
</script>
