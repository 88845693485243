<template>
  <div class="mt-15">
    
    <v-layout align-center justify-center>

      <v-card flat min-width="300" max-width="660" class="px-8">

      <div class="mx-auto mb-5 parallax-title" :style="TitleSize">
      Es gab leider ein Problem!
      </div><br>
      Die Zahlung wurde von Paypal abgebrochen. Versuchen Sie es noch mal oder spenden Sie bitte per Überweisung. Wir entschuldigen uns für diesen technischen Fehler. 
      </v-card>

    </v-layout>
  </div>
</template>



<script>
import Donation from "../components/Donation";

export default {
  name: 'Thanks',
  components: {
    Donation
  },
  computed: {
    TitleSize () {
            switch (this.$vuetify.breakpoint.name) {
            case 'xs': return 'font-size:20px'
            case 'sm': return 'font-size:25px'
            case 'md': return 'font-size:30px'
            case 'lg': return 'font-size:40px;'
            case 'xl': return 'font-size:50px;'
            default: return 'font-size:25px'

            }
        },
    HeartSize () {
            switch (this.$vuetify.breakpoint.name) {
            case 'xs': return '300px'
            case 'sm': return '400px'
            case 'md': return '500px'
            case 'lg': return '600px;'
            case 'xl': return '600px;'
            default: return '25px'

            }
        },

  }
}
</script>
