<template>
  <div class="mt-15">
      
  <v-carousel
    cycle
    :height="SliderHeight"
    hide-delimiter-background
    show-arrows-on-hover
  >
        <!-- <v-carousel-item
        src='../../public/img/slides/slide.jpg'
        >
        </v-carousel-item> -->
        <v-carousel-item
        src='../../public/img/slides/slide-3.jpg'
        >
        </v-carousel-item>
        <v-carousel-item
        src='../../public/img/slides/slide-2.jpg'
        >
        </v-carousel-item>
        
        <v-carousel-item
        src='../../public/img/slides/slide-4.jpg'
        >
        </v-carousel-item>
        <v-carousel-item
        src='../../public/img/slides/cherson.jpg'
        >
        </v-carousel-item>
        <v-carousel-item
        src='../../public/img/slides/slide-5.jpg'
        >
        </v-carousel-item>
        <v-carousel-item
        src='../../public/img/slides/slide-6.jpg'
        >
        </v-carousel-item>
        <v-carousel-item
        src='../../public/img/slides/slide-7.jpg'
        >
        </v-carousel-item>
        <v-carousel-item
        src='../../public/img/slides/slide-8.jpg'
        >
        </v-carousel-item>
    </v-carousel>
           <v-layout align-center justify-center>
              
            <v-card flat min-width="300" max-width="660" class="px-8 mt-10" style="text-align:justify;  font-size: 20px;">
              Das Jahr 2022 hat uns alle vor eine Situation gestellt, die wir in den letzten Jahrzehnten nicht mehr für möglich gehalten haben. Fast täglich erreichen uns neue erschreckende Nachrichten zum Krieg in der Ukraine und den gravierenden Auswirkungen der Klimakrise, und von einer friedlichen Welt scheinen wir uns immer weiter zu entfernen. Aktuell sind weltweit etwa 100 Millionen Menschen auf der Flucht oder wurden gewaltsam aus ihrer Heimat vertrieben. Einigen, die unter prekären Verhältnissen in ihren Heimatländern bleiben, versucht der Hilfsverein Nymphenburg vor Ort zu unterstützen, um ihnen in ihrer Heimat Chancen auf ein selbstbestimmtes Leben unter menschenwürdigen Bedingungen zu ermöglichen. Ein besonderer Fokus liegt dabei auf der Unterstützung von Frauen, Kindern, älteren und kranken Menschen.<br> 
              <br>
            Unsere Unterstützung von Menschen in der Ukraine ist nun im letzten Jahr fester Bestandteil davon geworden. Nach dem russischen Angriff auf die Ukraine starteten wir unmittelbar unsere Nothilfe und riefen dafür zu einer Sonder-Spendenaktion inklusive Sachspenden auf, die sehr erfolgreich war. Seitdem unterhalten wir am Grenzübergang Siret zwischen Rumänien und der Ukraine mit unseren verlässlichen rumänischen Partnern vom Roten Kreuz eine Präsenz, von der aus mehrere Versorgungsrouten für LKW-Konvois in die Süd- und Ostukraine angefahren werden. In den ersten Kriegswochen wurden vor allem Lebensmittel und Medikamente für innerhalb der Ukraine Geflüchtete Menschen beschafft und transportiert. Zurzeit stellen wir Survival-Kits und Wiederaufbau-Kits für Bewohner der Region Cherson zur Verfügung. 
            <br><br>
            Durch die Folgen des Krieges und der Pandemiejahre hat sich auch die Notsituation in vielen unserer anderen Projektgebieten massiv verschärft. Steigende Lebensmittel- und Energiepreise treffen besonders die Menschen am härtesten, die bisher schon in Armut leben mussten. Insbesondere in unseren Projekten in Albanien, Rumänien und Kenia spüren wir das sehr, da dort kaum soziale und staatliche Auffangsysteme existieren. 
            <br><br>
            Wir danken Ihnen für Ihr Interesse an der Arbeit des Hilfsverein Nymphenburg und Ihren Besuch auf unserer Webpage! 
                
            </v-card>
                  
        </v-layout>

  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  },
  data(){
    return{

    
        items: [
          {
            src: '../../public/img/slides/slide.jpg',
          },
          {
            src: '../../public/img/slides/slide-2.jpg',
          },
          {
            src: '../../public/img/slides/slide-2.jpg',
          },
          {
            src: '../../public/img/slides/slide-2.jpg',
          },
        ],
    }
  },
  computed: {
    SliderSubTitle () {
            switch (this.$vuetify.breakpoint.name) {
            case 'xs': return 'font-size:20px'
            case 'sm': return 'font-size:25px'
            case 'md': return 'font-size:30px'
            case 'lg': return 'font-size:40px;'
            case 'xl': return 'font-size:50px;'
            default: return 'font-size:25px'

            }
        },
        IconSize () {
            switch (this.$vuetify.breakpoint.name) {
            case 'xs': return '60px'
            case 'sm': return '75px'
            case 'md': return '90px'
            case 'lg': return '120px;'
            case 'xl': return '150px;'
            default: return '25px'

            }
        },
        SponsorLogoSize () {
            switch (this.$vuetify.breakpoint.name) {
            case 'xs': return '75px'
            case 'sm': return '85px'
            case 'md': return '100px'
            case 'lg': return '120px;'
            case 'xl': return '140px;'
            default: return '25px'

            }
        },

        MapSize () {
            switch (this.$vuetify.breakpoint.name) {
            case 'xs': return '300px'
            case 'sm': return '400px'
            case 'md': return '500px'
            case 'lg': return '600px;'
            case 'xl': return '600px;'
            default: return '25px'

            }
        },
        SliderTitle () {
            switch (this.$vuetify.breakpoint.name) {
            case 'xs': return 'font-size:30px; color:rgb(40,53,130); font-weight: bold;'
            case 'sm': return 'font-size:40px; color:rgb(40,53,130); font-weight: bold;'
            case 'md': return 'font-size:50px; color:rgb(40,53,130); font-weight: bold;'
            case 'lg': return 'font-size:80px; color:rgb(40,53,130); font-weight: bold;'
            case 'xl': return 'font-size:100px; color:rgb(40,53,130); font-weight: bold;'
            default: return 'font-size:40px; color:rgb(40,53,130); font-weight: bold;'

            }
        },
        SliderHeight () {
            switch (this.$vuetify.breakpoint.name) {
            case 'xs': return '300'
            case 'sm': return '400'
            case 'md': return '500'
            case 'lg': return '600'
            case 'xl': return '700'
            default: return '400'

            }
        },
        
  },
  methods:{
    GotoDonate: function() {
          this.$router.push('donate')
      }
  }
}
</script>
