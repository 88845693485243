<template>

  <div class="mt-8">

    <v-layout align-center justify-center>

      <v-card flat min-width="300" max-width="1200" class="px-8 pt-10">
        <div v-for="objEvent in arrEvent" :key="objEvent.strTitle">
              <Event
                :str-title="objEvent.strTitle"
                :txt-body="objEvent.txtBody"
                :str-img-path="objEvent.strImgPath"

              ></Event>
        </div>
      </v-card>
    </v-layout>
    
  
  </div>
</template>



<script>
import Event from "../components/Event";

export default {
name: 'Events',
  
components: {
    Event
},
data() {
  return {
    arrEvent: [
      
      {
        strTitle: "Hochzeit Sophie Evekink & Prinz Ludwig von Bayern",
        txtBody: `
        Anstelle von Geschenken würde sich das Brautpaar über eine Spende an die Stiftung Hilfsverein Nymphenburg freuen.
Die Spenden gehen zu gleichen Teilen an
Learning Lions Kenia und an vom Brautpaar ausgewählte Projekte des Hilfsvereins.
`,
        strImgPath: "logo with flowers.jpg"
      }
    ]
  }

},



}


</script>
