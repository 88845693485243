<template>

   <div class="mt-15">
      
<v-img
  :height="ImageHeight"
  src="../../public/img/projects/kenya/kenya_title.jpg"
>
  <div style="
    margin: 0;
    position: absolute;
    top: 50%; 
    left: 50%; 
    font-size: 60px; 
    font-weight: bold; 
    color: white;" >
    KENIA
  </div>
</v-img>

<v-layout align-center justify-center>

    <v-card flat min-width="300" max-width="1200" class="px-8 pt-10">
      Bildung ist auf vielerlei Weise eine der nachhaltigsten Entwicklungshilfen. Sie fördert die Aufklärung und kann Frieden stiften. Der Hilfsverein bringt Kinder verfeindeter Volksstämme friedlich in der Schule zusammen und ermöglicht Mädchen in Internaten den sicheren Zugang zu Bildung und einer Zukunft. Die junge Generation hat so die Chance das Land aufzubauen.  

      Außerdem zählt der Ausbau der Wasserversorgung und die Versorgung von Kindern mit Nahrungsmitteln zu einem wichtigen Fokus des Hilfsverein Nymphenburg in dieser Region. 
        <div v-for="objEvent in arrEvent" :key="objEvent.strTitle">
          <Project
            :str-title="objEvent.strTitle"
            :txt-body="objEvent.txtBody"
            :str-img-path="objEvent.strImgPath"

          ></Project>
    </div>
    
    </v-card>

</v-layout>

  
    
  </div>
</template>

<script>

import Project from '../components/Project'; 

export default {
    
    components:{
      Project
    },
    data: function(){
        return{

            blnCampaign: false,
            arrEvent: [
              {
                strTitle: "Wasserprojekte Turkana",
                txtBody:
                `
                In Ostafrika herrschte in vielen Regionen im Jahr 2022 eine der schlimmsten Dürren seit vielen Jahren. Es fehlt an Wasser und Nahrung für Menschen und Tiere und die Hungersnot verschlimmerte sich aufgrund der Ereignisse in Europa noch zusätzlich. Durch Ernteausfälle und die unterbrochene Ausfuhr von ukrainischem Getreide in Verbindung mit der internationalen Energiekrise steigen die Lebensmittelpreise rasant an. In Kenia haben sich die Preise im Vergleich zum Vorjahr verdoppelt. Dies trifft besonders die armen Regionen im Norden extrem, da dort der Großteil der Bevölkerung unter der Armutsgrenze lebt und die Region stark von der Dürre heimgesucht wurde.
                Der Hilfsverein Nymphenburg hat über die Jahre bereits 5 Brunnensysteme gebaut, zuletzt 2021. Außerdem wurden Staudämme und Erddämme zum Auffangen von Regenwasser errichtet, die bis zu zwei Jahre lang Regenwasser speichern können.  
                `,
                strImgPath: "kenya/water_project.jpg"
              },
              {
                strTitle: "Schulessen",
                txtBody:
                `
               Der Hilfsverein Nymphenburg hat der Ernährung der Kinder in der Region Turkana höchste Priorität eingeräumt. Wir haben kurzfristig die Ernährungsprogramme an den von uns errichteten Schulen und Kindergärten übernommen, da der kenianische Staat und andere Hilfsorganisationen aufgrund der steigenden Lebensmittelpreise die Ausgabe von Schulessen reduzieren mussten. 
                `,
                strImgPath: "kenya/queen_of_peace.jpg"
              },
               {
                strTitle: "Wakanda Girls",
                txtBody:
                `
                Zurzeit noch im Bau befindet sich das Mädcheninternat „Wakanda Girls“, das der Hilfsverein Nymphenburg gemeinsam mit der lokalen Regierung und Sternstunden e.V. baut. Ziel ist es eine fortschrittliche Schule zu schaffen, in der Mädchen neben einer Schulbildung IT Trainings erhalten. Das Besondere an dieser Schule ist eine komplette digitale Ausstattung der Klassenzimmer. Für den regulären Unterricht wird mit Papier gearbeitet. Bei Bedarf können die Tische aufgeklappt und so in Computer-Terminals verwandelt werden. Diese sind wiederum mit einem Server verbunden, sodass die Schülerinnen auf einen reichen Schatz an Lehrmaterial zurückgreifen können. 
 
 
                `,
                strImgPath: "kenya/wakanda.jpg"
              },
              
 {
                strTitle: "Value Villages",
                txtBody:
                `
                In den Dürreregionen ist es besonders wichtig, die Bewohner beim Erwirtschaften ihres eigenen Lebensunterhaltes zu unterstützen. Die „Value Villages“, die wir seit 2018 ins Leben gerufen haben, fördern Landwirtschaft in der Wüste. Die Idee ist die Gegebenheiten vor Ort sinnvoll wirtschaftlich zu nutzen: Von Gewächshäusern in der Wüste bis zu einem Betrieb, in dem die Reste von Fischen aus dem Turkanasee zu Hühnerfutter verarbeitet werden. Nach einigen Jahren Erfahrung wissen wir inzwischen, dass man den Ackerbau in der Wüste ganz anders planen muss als in klassischen Anbaugebieten. Wegen des hohen Bewässerungsbedarfs lohnt sich der Verkauf von Mais und Gemüse kaum. Wir arbeiten nun an einem kombinierten Konzept: Gemüse wird nur für den Eigenbedarf gepflanzt; hochpreisige Gewürze, antizyklisch angebaut und getrocknet, kommen in den Verkauf. So lassen sich Gewinne erzielen, mit denen anderes zugekauft werden kann. 
                `,
                strImgPath: "kenya/shade_nets.jpg"
              },

          ]

        }

    }
    
}
</script>
