<template>
  <div class="mt-5">

  
      
    <v-card >
        <v-row>
          <v-select
                  label="Spenden Zweck:"
                  :items="['Freie Spende', 'Ukraine', 'Albanien', 'Rumänien','Ost-Afrika','Hochzeit Sophie Evekink & Prinz Ludwig von Bayern']"
                  v-model="strDedication"
                  class="ma-4"
                  >
              </v-select>
        </v-row>
        <v-row>
            <v-col cols="8">
                <v-text-field
                    type="number"
                    label="Betrag:"
                    placeholder='Betrag in Euro'
                    v-model="dblAmount"

                ></v-text-field>
            </v-col>
            <v-col>
                <PayPal 
                    :amount="dblAmount" 
                    currency="EUR" 
                    :client="credentials"
                    :invoice-number="strDedication"
                    env="production" 
                    locale="de_DE"
                    @payment-completed="GotoThanks"
                    @paypal-paymentCompleted="GotoThanks"
                    @payment-cancelled="GotoCancelled"
                    @paypal-paymentCancelled="GotoCancelled"
                    class="mt-4"
                ></PayPal>
            </v-col>
        </v-row>
        
        
        
    

    </v-card>
    
  </div>
</template>

<script>
import { stringify } from "json5";
import PayPal from "vue-paypal-checkout";

export default {
  name: "Donation",
  components: {
    PayPal
  },
  props: {
    msg: String,
    strDedication: {
      type: String, 
      default: "Freie Spende"
    },
  },
  data() {
    return {
      
      dblAmount: "",
      
      successUrl: `./thanks`,
      
    //   cancelUrl: `${document.location.href}cancel.html`,
    //   home: document.location.href,
      credentials: {
        sandbox:
          "AcOuW_GFIWx0uL-7s5OSNWpQXLhb6pLNihfsJeol-uSY86q8bJxtKTH2dFNbTmfQAcDcQSYQ6zZamDT9",
        production:
          "AXeb_j0_uYUcx0hVnR02gsrK93IolmH5WjXjSfLVm52zaUuyeQi0GCaq7uB91j-BbyBCKjjNw3vKv3_D"
          // hilfsverein actual
      },
      
    };
  },
  created() {
    
  },
  computed: {
    
  },
  methods: {

      GotoThanks: function() {
        console.log("donation Success")
          this.$router.push('./thanks')
          
      },
      GotoCancelled: function() {
        console.log("donation Success")
          this.$router.push('./cancelled')
          
      }
    
  }
};
</script>

