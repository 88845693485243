<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" width="600px">
        <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mt-2"
          color="black"
          dark
            depressed
            small
          v-bind="attrs"
          v-on="on"
        >
          Impressum
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">Impressum</span>
        </v-card-title>
        <v-card-text>

            <p>&nbsp;</p>
<p>Angaben gem&auml;&szlig; &sect; 5 TMG</p>
<p>&nbsp;</p>
<p>Stiftung Hilfsverein Nymphenburg<br /> Schloß Nymphenburg 11<br /> 80638 München</p>
<p>Vertreten durch: Ludwig Prinz von Bayern, Stephan Warsberg</p>
<p>E-Mail: <a href="mailto:info@hilfsverein-nymphenburg.de">info@hilfsverein-nymphenburg.de</a><br /> Website: <a href="http://www.xn--lwenmarsch-ecb.de">www.hilfsverein-nymphenburg.de</a></p>

<p>&nbsp;</p>
<p><strong>Haftungsausschluss:</strong><br /> <br /> <u>Haftung f&uuml;r Inhalte</u><br /> <br /> Die Inhalte unserer Seiten wurden mit gr&ouml;&szlig;ter Sorgfalt erstellt. F&uuml;r die Richtigkeit, Vollst&auml;ndigkeit und Aktualit&auml;t der Inhalte k&ouml;nnen wir jedoch keine Gew&auml;hr &uuml;bernehmen. Als Diensteanbieter sind wir gem&auml;&szlig; &sect; 7 Abs.1 TMG f&uuml;r eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach &sect;&sect; 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, &uuml;bermittelte oder gespeicherte fremde Informationen zu &uuml;berwachen oder nach Umst&auml;nden zu forschen, die auf eine rechtswidrige T&auml;tigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unber&uuml;hrt. Eine diesbez&uuml;gliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung m&ouml;glich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.<br /> <br /> <u>Haftung f&uuml;r Links</u><br /> <br /> Unser Angebot enth&auml;lt Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb k&ouml;nnen wir f&uuml;r diese fremden Inhalte auch keine Gew&auml;hr &uuml;bernehmen. F&uuml;r die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf m&ouml;gliche Rechtsverst&ouml;&szlig;e &uuml;berpr&uuml;ft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>
<p>&nbsp;</p>
<h1>Datenschutzerkl&auml;rung</h1>
<p>Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:</p>
<p>Ludwig Prinz von Bayern </p>
<h2>Ihre Betroffenenrechte</h2>
<p>Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten k&ouml;nnen Sie jederzeit folgende Rechte aus&uuml;ben:</p>
<ul>
<li>Auskunft &uuml;ber Ihre bei uns gespeicherten Daten und deren Verarbeitung (Art. 15 DSGVO),</li>
<li>Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),</li>
<li>L&ouml;schung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
<li>Einschr&auml;nkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch nicht l&ouml;schen d&uuml;rfen (Art. 18 DSGVO),</li>
<li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO) und</li>
<li>Daten&uuml;bertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben (Art. 20 DSGVO).</li>
</ul>
<p>Sofern Sie uns eine Einwilligung erteilt haben, k&ouml;nnen Sie diese jederzeit mit Wirkung f&uuml;r die Zukunft widerrufen.</p>
<p>Sie k&ouml;nnen sich jederzeit mit einer Beschwerde an eine Aufsichtsbeh&ouml;rde wenden, z. B. an die zust&auml;ndige Aufsichtsbeh&ouml;rde des Bundeslands Ihres Wohnsitzes oder an die f&uuml;r uns als verantwortliche Stelle zust&auml;ndige Beh&ouml;rde.</p>
<p>Eine Liste der Aufsichtsbeh&ouml;rden (f&uuml;r den nicht&ouml;ffentlichen Bereich) mit Anschrift finden Sie unter:&nbsp;<a style="font-style: inherit; font-weight: inherit;" href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.</p>
<h2>Erfassung allgemeiner Informationen beim Besuch unserer Website</h2>
<h3>Art und Zweck der Verarbeitung:</h3>
<p>Wenn Sie auf unsere Website zugreifen, d.h., wenn Sie sich nicht registrieren oder anderweitig Informationen &uuml;bermitteln, werden automatisch Informationen allgemeiner Natur erfasst. Diese Informationen (Server-Logfiles) beinhalten etwa die Art des Webbrowsers, das verwendete Betriebssystem, den Domainnamen Ihres Internet-Service-Providers, Ihre IP-Adresse und &auml;hnliches.</p>
<p>Sie werden insbesondere zu folgenden Zwecken verarbeitet:</p>
<ul>
<li>Sicherstellung eines problemlosen Verbindungsaufbaus der Website,</li>
<li>Sicherstellung einer reibungslosen Nutzung unserer Website,</li>
<li>Auswertung der Systemsicherheit und -stabilit&auml;t sowie</li>
<li>zu weiteren administrativen Zwecken.</li>
</ul>
<p>Wir verwenden Ihre Daten nicht, um R&uuml;ckschl&uuml;sse auf Ihre Person zu ziehen. Informationen dieser Art werden von uns ggfs. statistisch ausgewertet, um unseren Internetauftritt und die dahinterstehende Technik zu optimieren.</p>
<h3>Rechtsgrundlage:</h3>
<p>Die Verarbeitung erfolgt gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an der Verbesserung der Stabilit&auml;t und Funktionalit&auml;t unserer Website.</p>
<h3>Empf&auml;nger:</h3>
<p>Empf&auml;nger der Daten sind ggf. technische Dienstleister, die f&uuml;r den Betrieb und die Wartung unserer Webseite als Auftragsverarbeiter t&auml;tig werden.</p>
<h3>Speicherdauer:</h3>
<p>Die Daten werden gel&ouml;scht, sobald diese f&uuml;r den Zweck der Erhebung nicht mehr erforderlich sind. Dies ist f&uuml;r die Daten, die der Bereitstellung der Webseite dienen, grunds&auml;tzlich der Fall, wenn die jeweilige Sitzung beendet ist.</p>
<h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
<p>Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder gesetzlich noch vertraglich vorgeschrieben. Ohne die IP-Adresse ist jedoch der Dienst und die Funktionsf&auml;higkeit unserer Website nicht gew&auml;hrleistet. Zudem k&ouml;nnen einzelne Dienste und Services nicht verf&uuml;gbar oder eingeschr&auml;nkt sein. Aus diesem Grund ist ein Widerspruch ausgeschlossen.</p>
<h2>Cookies</h2>
<h3>Art und Zweck der Verarbeitung:</h3>
<p>Wie viele andere Webseiten verwenden wir auch so genannte &bdquo;Cookies&ldquo;. Bei Cookies handelt es sich um kleine Textdateien, die auf Ihrem Endger&auml;t (Laptop, Tablet, Smartphone o.&auml;.) gespeichert werden, wenn Sie unsere Webseite besuchen.</p>
<p>Hierdurch erhalten wir bestimmte Daten wie z. B. IP-Adresse, verwendeter Browser und Betriebssystem.</p>
<p>Cookies k&ouml;nnen nicht verwendet werden, um Programme zu starten oder Viren auf einen Computer zu &uuml;bertragen. Anhand der in Cookies enthaltenen Informationen k&ouml;nnen wir Ihnen die Navigation erleichtern und die korrekte Anzeige unserer Webseiten erm&ouml;glichen.</p>
<p>In keinem Fall werden die von uns erfassten Daten an Dritte weitergegeben oder ohne Ihre Einwilligung eine Verkn&uuml;pfung mit personenbezogenen Daten hergestellt.</p>
<p>Nat&uuml;rlich k&ouml;nnen Sie unsere Website grunds&auml;tzlich auch ohne Cookies betrachten. Internet-Browser sind regelm&auml;&szlig;ig so eingestellt, dass sie Cookies akzeptieren. Im Allgemeinen k&ouml;nnen Sie die Verwendung von Cookies jederzeit &uuml;ber die Einstellungen Ihres Browsers deaktivieren. Bitte verwenden Sie die Hilfefunktionen Ihres Internetbrowsers, um zu erfahren, wie Sie diese Einstellungen &auml;ndern k&ouml;nnen. Bitte beachten Sie, dass einzelne Funktionen unserer Website m&ouml;glicherweise nicht funktionieren, wenn Sie die Verwendung von Cookies deaktiviert haben.</p>
<h3>Speicherdauer und eingesetzte Cookies:</h3>
<p>Soweit Sie uns durch Ihre Browsereinstellungen oder Zustimmung die Verwendung von Cookies erlauben, k&ouml;nnen folgende Cookies auf unseren Webseiten zum Einsatz kommen:</p>

<h4>Eigene Cookies</h4>
<p>
  Wir setzen Ein Cookie ein dass Ihre eine JWT Code für Ihre Authetifizierung speichert und ein weiteres das vermerkt, dass wir Sie aud die Datenschutzbestimmmung hingewiesen haben. 
  Die Cookies haben eine maximale Gültigkeit von 30 Tagen. 
</p>
<p>Soweit diese Cookies (auch) personenbezogene Daten betreffen k&ouml;nnen, informieren wir Sie dar&uuml;ber in den folgenden Abschnitten.</p>
<p>Sie k&ouml;nnen &uuml;ber Ihre Browsereinstellungen einzelne Cookies oder den gesamten Cookie-Bestand l&ouml;schen. Dar&uuml;ber hinaus erhalten Sie Informationen und Anleitungen, wie diese Cookies gel&ouml;scht oder deren Speicherung vorab blockiert werden k&ouml;nnen. Je nach Anbieter Ihres Browsers finden Sie die notwendigen Informationen unter den nachfolgenden Links:</p>
<ul>
<li>Mozilla Firefox:&nbsp;<a style="font-style: inherit; font-weight: inherit;" href="https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen">https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen</a></li>
<li>Internet Explorer:&nbsp;<a style="font-style: inherit; font-weight: inherit;" href="https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies">https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies</a></li>
<li>Google Chrome:&nbsp;<a style="font-style: inherit; font-weight: inherit;" href="https://support.google.com/accounts/answer/61416?hl=de">https://support.google.com/accounts/answer/61416?hl=de</a></li>
<li>Opera:&nbsp;<a style="font-style: inherit; font-weight: inherit;" href="http://www.opera.com/de/help">http://www.opera.com/de/help</a></li>
<li>Safari:&nbsp;<a style="font-style: inherit; font-weight: inherit;" href="https://support.apple.com/kb/PH17191?locale=de_DE&amp;viewlocale=de_DE">https://support.apple.com/kb/PH17191?locale=de_DE&amp;viewlocale=de_DE</a></li>
</ul>
<h2>Registrierung auf unserer Website</h2>
<h3>Art und Zweck der Verarbeitung:</h3>
<p>Bei der Registrierung f&uuml;r die Nutzung unserer personalisierten Leistungen werden einige personenbezogene Daten erhoben, wie Name, Anschrift, Kontakt- und Kommunikationsdaten (z. B. Telefonnummer und E-Mail-Adresse). Sind Sie bei uns registriert, k&ouml;nnen Sie auf Inhalte und Leistungen zugreifen, die wir nur registrierten Nutzern anbieten. Angemeldete Nutzer haben zudem die M&ouml;glichkeit, bei Bedarf die bei Registrierung angegebenen Daten jederzeit zu &auml;ndern oder zu l&ouml;schen. Selbstverst&auml;ndlich erteilen wir Ihnen dar&uuml;ber hinaus jederzeit Auskunft &uuml;ber die von uns &uuml;ber Sie gespeicherten personenbezogenen Daten.</p>
<h3>Rechtsgrundlage:</h3>
<p>Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt auf Grundlage einer Einwilligung des Nutzers (Art. 6 Abs. 1 lit. a DSGVO).</p>
<p>Dient die Registrierung der Erf&uuml;llung eines Vertrages, dessen Vertragspartei die betroffene Person ist oder der Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen, so ist zus&auml;tzliche Rechtsgrundlage f&uuml;r die Verarbeitung der Daten Art. 6 Abs. 1 lit. b DSGVO.</p>
<h3>Empf&auml;nger:</h3>
<p>Empf&auml;nger der Daten sind ggf. technische Dienstleister, die f&uuml;r den Betrieb und die Wartung unserer Website als Auftragsverarbeiter t&auml;tig werden.</p>
<h3>Speicherdauer:</h3>
<p>Daten werden in diesem Zusammenhang nur verarbeitet, solange die entsprechende Einwilligung vorliegt. Danach werden sie gel&ouml;scht, soweit keine gesetzlichen Aufbewahrungspflichten entgegenstehen. Zur Kontaktaufnahme in diesem Zusammenhang nutzen Sie bitte die am Ende dieser Datenschutzerkl&auml;rung angegebenen Kontaktdaten.</p>
<h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
<p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig, allein auf Basis Ihrer Einwilligung. Ohne die Bereitstellung Ihrer personenbezogenen Daten k&ouml;nnen wir Ihnen keinen Zugang auf unsere angebotenen Inhalte und Leistungen gew&auml;hren.</p>
<h2>Kommentarfunktion</h2>
<h3>Art und Zweck der Verarbeitung:</h3>
<p>Wenn Nutzer Kommentare auf unserer Website hinterlassen, werden neben diesen Angaben auch der Zeitpunkt ihrer Erstellung und der zuvor durch den Websitebesucher gew&auml;hlte Nutzername gespeichert. Dies dient unserer Sicherheit, da wir f&uuml;r widerrechtliche Inhalte auf unserer Webseite belangt werden k&ouml;nnen, auch wenn diese durch Benutzer erstellt wurden.</p>
<h3>Rechtsgrundlage:</h3>
<p>Die Verarbeitung der als Kommentar eingegebenen Daten erfolgt auf der Grundlage eines berechtigten Interesses (Art. 6 Abs. 1 lit. f DSGVO).</p>
<p>Durch Bereitstellung der Kommentarfunktion m&ouml;chten wir Ihnen eine unkomplizierte Interaktion erm&ouml;glichen. Ihre gemachten Angaben werden zum Zwecke der Bearbeitung der Anfrage sowie f&uuml;r m&ouml;gliche Anschlussfragen gespeichert.</p>
<h3>Empf&auml;nger:</h3>
<p>Empf&auml;nger der Daten sind ggf. Auftragsverarbeiter.</p>
<h3>Speicherdauer:</h3>
<p>Die Daten werden gel&ouml;scht, sobald diese f&uuml;r den Zweck der Erhebung nicht mehr erforderlich sind. Dies ist grunds&auml;tzlich der Fall, wenn die Kommunikation mit dem Nutzer abgeschlossen ist und das Unternehmen den Umst&auml;nden entnehmen kann, dass der betroffene Sachverhalt abschlie&szlig;end gekl&auml;rt ist.</p>
<h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
<p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig. Ohne die Bereitstellung Ihrer personenbezogenen Daten k&ouml;nnen wir Ihnen keinen Zugang zu unserer Kommentarfunktion gew&auml;hren.</p>
<h2>Newsletter</h2>
<h3>Art und Zweck der Verarbeitung:</h3>
<p>Ihre Daten werden ausschlie&szlig;lich dazu verwendet, Ihnen den abonnierten Newsletter per E-Mail zuzustellen. Die Angabe Ihres Namens erfolgt, um Sie im Newsletter pers&ouml;nlich ansprechen zu k&ouml;nnen und ggf. zu identifizieren, falls Sie von Ihren Rechten als Betroffener Gebrauch machen wollen.</p>
<p>F&uuml;r den Empfang des Newsletters ist die Angabe Ihrer E-Mail-Adresse ausreichend. Bei der Anmeldung zum Bezug unseres Newsletters werden die von Ihnen angegebenen Daten ausschlie&szlig;lich f&uuml;r diesen Zweck verwendet. Abonnenten k&ouml;nnen auch &uuml;ber Umst&auml;nde per E-Mail informiert werden, die f&uuml;r den Dienst oder die Registrierung relevant sind (bspw. &Auml;nderungen des Newsletterangebots oder technische Gegebenheiten).</p>
<p>F&uuml;r eine wirksame Registrierung ben&ouml;tigen wir eine valide E-Mail-Adresse. Um zu &uuml;berpr&uuml;fen, dass eine Anmeldung tats&auml;chlich durch den Inhaber einer E-Mail-Adresse erfolgt, setzen wir das &bdquo;Double-opt-in&ldquo;-Verfahren ein. Hierzu protokollieren wir die Bestellung des Newsletters, den Versand einer Best&auml;tigungsmail und den Eingang der hiermit angeforderten Antwort. Weitere Daten werden nicht erhoben. Die Daten werden ausschlie&szlig;lich f&uuml;r den Newsletterversand verwendet und nicht an Dritte weitergegeben.</p>
<h3>Rechtsgrundlage:</h3>
<p>Auf Grundlage Ihrer ausdr&uuml;cklich erteilten Einwilligung (Art. 6 Abs. 1 lit. a DSGVO), &uuml;bersenden wir Ihnen regelm&auml;&szlig;ig unseren Newsletter bzw. vergleichbare Informationen per E-Mail an Ihre angegebene E-Mail-Adresse.</p>
<p>Die Einwilligung zur Speicherung Ihrer pers&ouml;nlichen Daten und ihrer Nutzung f&uuml;r den Newsletterversand k&ouml;nnen Sie jederzeit mit Wirkung f&uuml;r die Zukunft widerrufen. In jedem Newsletter findet sich dazu ein entsprechender Link. Au&szlig;erdem k&ouml;nnen Sie sich jederzeit auch direkt auf dieser Website abmelden oder uns Ihren Widerruf &uuml;ber die am Ende dieser Datenschutzhinweise angegebene Kontaktm&ouml;glichkeit mitteilen.</p>
<h3>Empf&auml;nger:</h3>
<p>Empf&auml;nger der Daten sind ggf. Auftragsverarbeiter.</p>
<h3>Speicherdauer:</h3>
<p>Die Daten werden in diesem Zusammenhang nur verarbeitet, solange die entsprechende Einwilligung vorliegt. Danach werden sie gel&ouml;scht.</p>
<h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
<p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig, allein auf Basis Ihrer Einwilligung. Ohne bestehende Einwilligung k&ouml;nnen wir Ihnen unseren Newsletter leider nicht zusenden.</p>
<h2>Kontaktformular</h2>
<h3>Art und Zweck der Verarbeitung:</h3>
<p>Die von Ihnen eingegebenen Daten werden zum Zweck der individuellen Kommunikation mit Ihnen gespeichert. Hierf&uuml;r ist die Angabe einer validen E-Mail-Adresse sowie Ihres Namens erforderlich. Diese dient der Zuordnung der Anfrage und der anschlie&szlig;enden Beantwortung derselben. Die Angabe weiterer Daten ist optional.</p>
<h3>Rechtsgrundlage:</h3>
<p>Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt auf der Grundlage eines berechtigten Interesses (Art. 6 Abs. 1 lit. f DSGVO).</p>
<p>Durch Bereitstellung des Kontaktformulars m&ouml;chten wir Ihnen eine unkomplizierte Kontaktaufnahme erm&ouml;glichen. Ihre gemachten Angaben werden zum Zwecke der Bearbeitung der Anfrage sowie f&uuml;r m&ouml;gliche Anschlussfragen gespeichert.</p>
<p>Sofern Sie mit uns Kontakt aufnehmen, um ein Angebot zu erfragen, erfolgt die Verarbeitung der in das Kontaktformular eingegebenen Daten zur Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen (Art. 6 Abs. 1 lit. b DSGVO).</p>
<h3>Empf&auml;nger:</h3>
<p>Empf&auml;nger der Daten sind ggf. Auftragsverarbeiter.</p>
<h3>Speicherdauer:</h3>
<p>Daten werden sp&auml;testens 6 Monate nach Bearbeitung der Anfrage gel&ouml;scht.</p>
<p>Sofern es zu einem Vertragsverh&auml;ltnis kommt, unterliegen wir den gesetzlichen Aufbewahrungsfristen nach HGB und l&ouml;schen Ihre Daten nach Ablauf dieser Fristen.</p>
<h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
<p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig. Wir k&ouml;nnen Ihre Anfrage jedoch nur bearbeiten, sofern Sie uns Ihren Namen, Ihre E-Mail-Adresse und den Grund der Anfrage mitteilen.</p>
<h2>Verwendung von Google Analytics</h2>
<h3>Art und Zweck der Verarbeitung:</h3>
<p>Diese Website benutzt Google Analytics, einen Webanalysedienst der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA (nachfolgend: &bdquo;Google&ldquo;). Google Analytics verwendet sog. &bdquo;Cookies&ldquo;, also Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Webseite durch Sie erm&ouml;glichen. Die durch das Cookie erzeugten Informationen &uuml;ber Ihre Benutzung dieser Webseite werden in der Regel an einen Server von Google in den USA &uuml;bertragen und dort gespeichert. Aufgrund der Aktivierung der IP-Anonymisierung auf diesen Webseiten, wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europ&auml;ischen Union oder in anderen Vertragsstaaten des Abkommens &uuml;ber den Europ&auml;ischen Wirtschaftsraum zuvor gek&uuml;rzt. Nur in Ausnahmef&auml;llen wird die volle IP-Adresse an einen Server von Google in den USA &uuml;bertragen und dort gek&uuml;rzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Webseite auszuwerten, um Reports &uuml;ber die Webseitenaktivit&auml;ten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegen&uuml;ber dem Webseitenbetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser &uuml;bermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengef&uuml;hrt.</p>
<p>Die Zwecke der Datenverarbeitung liegen in der Auswertung der Nutzung der Website und in der Zusammenstellung von Reports &uuml;ber Aktivit&auml;ten auf der Website. Auf Grundlage der Nutzung der Website und des Internets sollen dann weitere verbundene Dienstleistungen erbracht werden.</p>
<h3>Rechtsgrundlage:</h3>
<p>Die Verarbeitung der Daten erfolgt auf Grundlage einer Einwilligung des Nutzers (Art. 6 Abs. 1 lit. a DSGVO).</p>
<h3>Empf&auml;nger:</h3>
<p>Empf&auml;nger der Daten ist Google als Auftragsverarbeiter. Hierf&uuml;r haben wir mit Google den entsprechenden Auftragsverarbeitungsvertrag abgeschlossen.</p>
<h3>Speicherdauer:</h3>
<p>Die L&ouml;schung der Daten erfolgt, sobald diese f&uuml;r unsere Aufzeichnungszwecke nicht mehr erforderlich sind.</p>
<h3>Drittlandtransfer:</h3>
<p>Google verarbeitet Ihre Daten in den USA und hat sich dem EU_US Privacy Shield unterworfen&nbsp;<a style="font-style: inherit; font-weight: inherit;" href="https://www.privacyshield.gov/EU-US-Framework">https://www.privacyshield.gov/EU-US-Framework</a>.</p>
<h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
<p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig, allein auf Basis Ihrer Einwilligung. Sofern Sie den Zugriff unterbinden, kann es hierdurch zu Funktionseinschr&auml;nkungen auf der Website kommen.</p>
<h3>Widerruf der Einwilligung:</h3>
<p>Sie k&ouml;nnen die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht s&auml;mtliche Funktionen dieser Website vollumf&auml;nglich werden nutzen k&ouml;nnen. Sie k&ouml;nnen dar&uuml;ber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Webseite bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link verf&uuml;gbare Browser-Plugin herunterladen und installieren:&nbsp;<a style="font-style: inherit; font-weight: inherit;" href="http://tools.google.com/dlpage/gaoptout?hl=de">Browser Add On zur Deaktivierung von Google Analytics</a>.</p>
<p>Zus&auml;tzlich oder als Alternative zum Browser-Add-On k&ouml;nnen Sie das Tracking durch Google Analytics auf unseren Seiten unterbinden, indem Sie&nbsp;<a style="font-style: inherit; font-weight: inherit;" href="https://www.activemind.de/datenschutz/generatoren/datenschutzerklaerung/ergebnis/">diesen Link anklicken</a>. Dabei wird ein Opt-out-Cookie auf Ihrem Ger&auml;t installiert. Damit wird die Erfassung durch Google Analytics f&uuml;r diese Website und f&uuml;r diesen Browser zuk&uuml;nftig verhindert, so lange das Cookie in Ihrem Browser installiert bleibt.</p>
<h3>Profiling:</h3>
<p>Mit Hilfe des Tracking-Tools Google Analytics kann das Verhalten der Besucher der Webseite bewertet und die Interessen analysiert werden. Hierzu erstellen wir ein pseudonymes Nutzerprofil.</p>
<h2>Verwendung von Scriptbibliotheken (Google Webfonts)</h2>
<h3>Art und Zweck der Verarbeitung:</h3>
<p>Um unsere Inhalte browser&uuml;bergreifend korrekt und grafisch ansprechend darzustellen, verwenden wir auf dieser Website &bdquo;Google Web Fonts&ldquo; der Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; nachfolgend &bdquo;Google&ldquo;) zur Darstellung von Schriften.</p>
<p>Die Datenschutzrichtlinie des Bibliothekbetreibers Google finden Sie hier:&nbsp;<a style="font-style: inherit; font-weight: inherit;" href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a></p>
<h3>Rechtsgrundlage:</h3>
<p>Rechtsgrundlage f&uuml;r die Einbindung von Google Webfonts und dem damit verbundenen Datentransfer zu Google ist Ihre Einwilligung (Art. 6 Abs. 1 lit. a DSGVO).</p>
<h3>Empf&auml;nger:</h3>
<p>Der Aufruf von Scriptbibliotheken oder Schriftbibliotheken l&ouml;st automatisch eine Verbindung zum Betreiber der Bibliothek aus. Dabei ist es theoretisch m&ouml;glich &ndash; aktuell allerdings auch unklar ob und ggf. zu welchen Zwecken &ndash; dass der Betreiber in diesem Fall Google Daten erhebt.</p>
<h3>Speicherdauer:</h3>
<p>Wir erheben keine personenbezogenen Daten, durch die Einbindung von Google Webfonts.</p>
<p>Weitere Informationen zu Google Web Fonts finden Sie unter&nbsp;<a style="font-style: inherit; font-weight: inherit;" href="https://developers.google.com/fonts/faq">https://developers.google.com/fonts/faq</a>&nbsp;und in der Datenschutzerkl&auml;rung von Google:&nbsp;<a style="font-style: inherit; font-weight: inherit;" href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>.</p>
<h3>Drittlandtransfer:</h3>
<p>Google verarbeitet Ihre Daten in den USA und hat sich dem EU_US Privacy Shield unterworfen&nbsp;<a style="font-style: inherit; font-weight: inherit;" href="https://www.privacyshield.gov/EU-US-Framework">https://www.privacyshield.gov/EU-US-Framework</a>.</p>
<h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
<p>Die Bereitstellung der personenbezogenen Daten ist weder gesetzlich, noch vertraglich vorgeschrieben. Allerdings kann ggfs. die korrekte Darstellung der Inhalte durch Standardschriften nicht m&ouml;glich sein.</p>
<h3>Widerruf der Einwilligung:</h3>
<p>Zur Darstellung der Inhalte wird regelm&auml;&szlig;ig die Programmiersprache JavaScript verwendet. Sie k&ouml;nnen der Datenverarbeitung daher widersprechen, indem Sie die Ausf&uuml;hrung von JavaScript in Ihrem Browser deaktivieren oder einen JavaScript-Blocker installieren. Bitte beachten Sie, dass es hierdurch zu Funktionseinschr&auml;nkungen auf der Website kommen kann.</p>
<h2>Verwendung von Google Maps</h2>
<h3>Art und Zweck der Verarbeitung:</h3>
<p>Auf dieser Webseite nutzen wir das Angebot von Google Maps. Google Maps wird von Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (nachfolgend &bdquo;Google&ldquo;) betrieben. Dadurch k&ouml;nnen wir Ihnen interaktive Karten direkt in der Webseite anzeigen und erm&ouml;glichen Ihnen die komfortable Nutzung der Karten-Funktion.</p>
<p>N&auml;here Informationen &uuml;ber die Datenverarbeitung durch Google k&ouml;nnen Sie&nbsp;<a style="font-style: inherit; font-weight: inherit;" href="http://www.google.com/privacypolicy.html">den Google-Datenschutzhinweisen</a>&nbsp;entnehmen. Dort k&ouml;nnen Sie im Datenschutzcenter auch Ihre pers&ouml;nlichen Datenschutz-Einstellungen ver&auml;ndern.</p>
<p>Ausf&uuml;hrliche Anleitungen zur Verwaltung der eigenen Daten im Zusammenhang mit Google-Produkten<a style="font-style: inherit; font-weight: inherit;" href="http://www.dataliberation.org/">&nbsp;finden Sie hier</a>.</p>
<h3>Rechtsgrundlage:</h3>
<p>Rechtsgrundlage f&uuml;r die Einbindung von Google Maps und dem damit verbundenen Datentransfer zu Google ist Ihre Einwilligung (Art. 6 Abs. 1 lit. a DSGVO).</p>
<h3>Empf&auml;nger:</h3>
<p>Durch den Besuch der Webseite erh&auml;lt Google Informationen, dass Sie die entsprechende Unterseite unserer Webseite aufgerufen haben. Dies erfolgt unabh&auml;ngig davon, ob Google ein Nutzerkonto bereitstellt, &uuml;ber das Sie eingeloggt sind, oder ob keine Nutzerkonto besteht. Wenn Sie bei Google eingeloggt sind, werden Ihre Daten direkt Ihrem Konto zugeordnet.</p>
<p>Wenn Sie die Zuordnung in Ihrem Profil bei Google nicht w&uuml;nschen, m&uuml;ssen Sie sich vor Aktivierung des Buttons bei Google ausloggen. Google speichert Ihre Daten als Nutzungsprofile und nutzt sie f&uuml;r Zwecke der Werbung, Marktforschung und/oder bedarfsgerechter Gestaltung seiner Webseite. Eine solche Auswertung erfolgt insbesondere (selbst f&uuml;r nicht eingeloggte Nutzer) zur Erbringung bedarfsgerechter Werbung und um andere Nutzer des sozialen Netzwerks &uuml;ber Ihre Aktivit&auml;ten auf unserer Webseite zu informieren. Ihnen steht ein Widerspruchsrecht zu gegen die Bildung dieser Nutzerprofile, wobei Sie sich zur Aus&uuml;bung dessen an Google richten m&uuml;ssen.</p>
<h3>Speicherdauer:</h3>
<p>Wir erheben keine personenbezogenen Daten, durch die Einbindung von Google Maps.</p>
<h3>Drittlandtransfer:</h3>
<p>Google verarbeitet Ihre Daten in den USA und hat sich dem EU_US Privacy Shield unterworfen&nbsp;<a style="font-style: inherit; font-weight: inherit;" href="https://www.privacyshield.gov/EU-US-Framework">https://www.privacyshield.gov/EU-US-Framework</a>.</p>
<h3>Widerruf der Einwilligung:</h3>
<p>Wenn Sie nicht m&ouml;chten, dass Google &uuml;ber unseren Internetauftritt Daten &uuml;ber Sie erhebt, verarbeitet oder nutzt, k&ouml;nnen Sie in Ihrem Browsereinstellungen JavaScript deaktivieren. In diesem Fall k&ouml;nnen Sie unsere Webseite jedoch nicht oder nur eingeschr&auml;nkt nutzen.</p>
<h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
<p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig, allein auf Basis Ihrer Einwilligung. Sofern Sie den Zugriff unterbinden, kann es hierdurch zu Funktionseinschr&auml;nkungen auf der Website kommen.</p>
<h2>Eingebettete YouTube-Videos</h2>
<h3>Art und Zweck der Verarbeitung:</h3>
<p>Auf einigen unserer Webseiten betten wir YouTube-Videos ein. Betreiber der entsprechenden Plugins ist die YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA (nachfolgend &bdquo;YouTube&ldquo;). Wenn Sie eine Seite mit dem YouTube-Plugin besuchen, wird eine Verbindung zu Servern von YouTube hergestellt. Dabei wird YouTube mitgeteilt, welche Seiten Sie besuchen. Wenn Sie in Ihrem YouTube-Account eingeloggt sind, kann YouTube Ihr Surfverhalten Ihnen pers&ouml;nlich zuzuordnen. Dies verhindern Sie, indem Sie sich vorher aus Ihrem YouTube-Account ausloggen.</p>
<p>Wird ein YouTube-Video gestartet, setzt der Anbieter Cookies ein, die Hinweise &uuml;ber das Nutzerverhalten sammeln.</p>
<p>Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer Verarbeitung durch YouTube erhalten Sie in den Datenschutzerkl&auml;rungen des Anbieters, Dort erhalten Sie auch weitere Informationen zu Ihren diesbez&uuml;glichen Rechten und Einstellungsm&ouml;glichkeiten zum Schutze Ihrer Privatsph&auml;re (<a style="font-style: inherit; font-weight: inherit;" href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>). Google verarbeitet Ihre Daten in den USA und hat sich dem EU-US Privacy Shield unterworfen <a href="https://www.privacyshield.gov/EU-US-Framework">https://www.privacyshield.gov/EU-US-Framework</a></p>
<h3>Rechtsgrundlage:</h3>
<p>Rechtsgrundlage f&uuml;r die Einbindung von YouTube und dem damit verbundenen Datentransfer zu Google ist Ihre Einwilligung (Art. 6 Abs. 1 lit. a DSGVO).</p>
<h3>Empf&auml;nger:</h3>
<p>Der Aufruf von YouTube l&ouml;st automatisch eine Verbindung zu Google aus.</p>
<h3>Speicherdauer und Widerruf der Einwilligung:</h3>
<p>Wer das Speichern von Cookies f&uuml;r das Google-Ad-Programm deaktiviert hat, wird auch beim Anschauen von YouTube-Videos mit keinen solchen Cookies rechnen m&uuml;ssen. YouTube legt aber auch in anderen Cookies nicht-personenbezogene Nutzungsinformationen ab. M&ouml;chten Sie dies verhindern, so m&uuml;ssen Sie das Speichern von Cookies im Browser blockieren.</p>
<p>Weitere Informationen zum Datenschutz bei &bdquo;YouTube&ldquo; finden Sie in der Datenschutzerkl&auml;rung des Anbieters unter:&nbsp;<a style="font-style: inherit; font-weight: inherit;" href="https://www.google.de/intl/de/policies/privacy/">https://www.google.de/intl/de/policies/privacy/</a></p>
<h3>Drittlandtransfer:</h3>
<p>Google verarbeitet Ihre Daten in den USA und hat sich dem EU_US Privacy Shield unterworfen&nbsp;<a style="font-style: inherit; font-weight: inherit;" href="https://www.privacyshield.gov/EU-US-Framework">https://www.privacyshield.gov/EU-US-Framework</a>.</p>
<h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
<p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig, allein auf Basis Ihrer Einwilligung. Sofern Sie den Zugriff unterbinden, kann es hierdurch zu Funktionseinschr&auml;nkungen auf der Website kommen.</p>
<h2>SSL-Verschl&uuml;sselung</h2>
<p>Um die Sicherheit Ihrer Daten bei der &Uuml;bertragung zu sch&uuml;tzen, verwenden wir dem aktuellen Stand der Technik entsprechende Verschl&uuml;sselungsverfahren (z. B. SSL) &uuml;ber HTTPS.</p>
<h2>&Auml;nderung unserer Datenschutzbestimmungen</h2>
<p>Wir behalten uns vor, diese Datenschutzerkl&auml;rung anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um &Auml;nderungen unserer Leistungen in der Datenschutzerkl&auml;rung umzusetzen, z.B. bei der Einf&uuml;hrung neuer Services. F&uuml;r Ihren erneuten Besuch gilt dann die neue Datenschutzerkl&auml;rung.</p>
<h2>Fragen an den Datenschutzbeauftragten</h2>
<p>Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an die f&uuml;r den Datenschutz verantwortliche Person in unserer Organisation:</p>
<p><a href="mailto:info@hilfsverein-nymphenburg.de">info@hilfsverein-nymphenburg.de</a></p>
<p><em style="font-weight: inherit;">Die Datenschutzerkl&auml;rung wurde mithilfe der activeMind AG erstellt, den Experten f&uuml;r&nbsp;</em><em><a style="font-style: inherit; font-weight: inherit;" href="https://www.activemind.de/datenschutz/datenschutzbeauftragter/">externe Datenschutzbeauftragte</a></em><em>&nbsp;(Version #2019-04-10).</em></p>
<p>&nbsp;</p>




        </v-card-text>
        
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    data () {
      return {
        dialog: false,
      }
    },
  }
</script>
