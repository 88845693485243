<template>

  <div class="mt-15">
     
<v-img
 :height="ImageHeight"
 src="../../public/img/projects/albania/albania_title.jpg"
>
 <div style="
   margin: 0;
   position: absolute;
   top: 50%; 
   left: 50%; 
   font-size: 60px; 
   font-weight: bold; 
   color: white;" >
   ALBANIEN
 </div>
</v-img>

<v-layout align-center justify-center>

   <v-card flat min-width="300" max-width="1200" class="px-8 pt-10">
    Albanien verfügt über ertragreiche Land- und Forstwirtschaft, im Tourismus steckt großes Entwicklungspotential. Und doch ist es eines der ärmsten Länder Europas, denn die überall herrschende Korruption verhindert eine nachhaltige Entwicklung des Landes. Darunter leidet besonders das Sozialwesen und mit ihm die Ärmsten des Landes. Um ihnen zu helfen und der steigenden Auswanderung entgegenzuwirken, engagiert sich der Hilfsverein in verschiedenen Sozialprojekten.  
    <div v-for="objEvent in arrEvent" :key="objEvent.strTitle">
         <Project
           :str-title="objEvent.strTitle"
           :txt-body="objEvent.txtBody"
           :str-img-path="objEvent.strImgPath"

         ></Project>
   </div>
   
   </v-card>

</v-layout>

 
   
 </div>
</template>

<script>

import Project from '../components/Project'; 

export default {
   
   components:{
     Project
   },
   data: function(){
       return{

           blnCampaign: false,
           arrEvent: [
            {
                strTitle: "Klostergemeinden",
                txtBody:
                `
                Auch die Menschen in Albanien leiden unter den steigenden Lebensmittelpreisen, immer mehr Familien sind auf Unterstützung angewiesen. Diese leisten die Schwestern der Spirituellen Weggemeinschaft in Shkoder/Dobrac und Delvine. Seit vielen Jahren unterstützt der Hilfsverein Nymphenburg sie dabei medizinische und humanitäre Hilfe für bedürftige Menschen in ihren Regionen zu leisten.Das kleine Kloster „Mutter der Barmherzigkeit“ der Schwestern in Shkoder ist inzwischen Rettungsinsel für Menschen aus dem ganzen Land geworden. Sie sorgen sich um einsame Alte, bieten Notfallversorgung und Erste Hilfe, Nahrung für Arme, oder seelischen Beistand für ganze Familien. Oft müssen die Schwestern dafür in die entlegensten Dörfer fahren, was ohne den vom Hilfsverein Nymphenburg mitfinanzierten Geländewagen unmöglich wäre.  
                `,
                strImgPath: "albania/Bild2.jpg"
              },
              {
                strTitle: "Mitfinanzierung von Schulgeldern",
                txtBody:
                `
               Durch die Vermittlung der Schwestern in Shkoder unterstützen wir Familien durch die Mitfinanzierung von Schul- und Studiengeldern. Oftmals handelt es sich dabei um Familien, deren Kinder bislang in einer höheren Schule waren, aber familiäre Krisensituationen, wie schwere Erkrankung oder Tod eines Angehörigen, die Bezahlung der Schulgelder oder Lernmaterialien unmöglich gemacht haben. Dies betrifft auch Kinder, deren Familien in Blutrache leben und die deshalb eine private Schule besuchen müssen, um dort sicher zu sein. Die motivierten Schüler und Studenten bekommen durch die Mitfinanzierung des Hilfsvereins eine Bildungschance und somit eine Perspektive für die Zukunft. 
                `,
                strImgPath: "albania/schulgelder.jpg"
              },
               {
                strTitle: "Obdachlosenküche in Vlore",
                txtBody:
                `
                In Vlore unterstützen wir die Obdachlosenküche des Roten Kreuzes, die täglich warmes Mittagessen an Senioren und Obdachlosen ausgibt. Zusätzlich wird dort medizinische Betreuung angeboten, denn Arztbesuche sind teuer und eine staatliche Fürsorge gibt es nicht. Die Obdachlosenküche ist zudem für viele die einzige Möglichkeit für soziale Kontakte, besonders auch zu jüngeren Menschen. 
 
 
                `,
                strImgPath: "albania/Bild 1.jpg"
              },


            
         ]

       }

   }
   
}
</script>
