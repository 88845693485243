<template>

  <div class="mt-15">
     
<v-img
 :height="ImageHeight"
 src="../../public/img/projects/romania/romania_title.jpg"
>
 <div style="
   margin: 0;
   position: absolute;
   top: 50%; 
   left: 50%; 
   font-size: 60px; 
   font-weight: bold; 
   color: white;" >
   RUMÄNIEN
 </div>
</v-img>

<v-layout align-center justify-center>

   <v-card flat min-width="300" max-width="1200" class="px-8 pt-10">
    In Rumänien herrscht in vielen Teilen der Bevölkerung, insbesondere innerhalb der Volksgruppe der Roma, große Armut. Alte Menschen leiden unter der mangelhaften Grundversorgung mit Lebensmitteln und Medikamenten. Kinder und Jugendliche aus verarmten Familien sind oft Opfer von kriminellen Vereinigungen und Menschenhandel. Sie haben kaum eine Chance, später einen Arbeitsplatz in ihrem Land zu finden.   
    <div v-for="objEvent in arrEvent" :key="objEvent.strTitle">
         <Project
           :str-title="objEvent.strTitle"
           :txt-body="objEvent.txtBody"
           :str-img-path="objEvent.strImgPath"

         ></Project>
   </div>
   
   </v-card>

</v-layout>

 
   
 </div>
</template>

<script>

import Project from '../components/Project'; 

export default {
   
   components:{
     Project
   },
   data: function(){
       return{

           blnCampaign: false,
           arrEvent: [
            {
              strTitle: "Wohncontainer für Roma Familien",
                txtBody:
                `
                Rund 6 Millionen Menschen in Rumänien, etwa ein Drittel der Bevölkerung, sind von Armut und sozialer Ausgrenzung bedroht. Jedes zehnte Kind geht Abend für Abend hungrig ins Bett. Besonders Roma-Familien sind von Armut, sozialer Ausgrenzung und Stigmatisierung betroffen. Das hat großen Einfluss auf Gesundheit und Bildungschancen von Kindern.Vor einigen Jahren räumte die Gemeinde Focsani im Kreis Vrancea mehrere Häuser, die überwiegend von Roma-Familien bewohnt wurden, und stellte dort Strom und Wasser ab. Ersatzwohnungen wurden nicht zur Verfügung gestellt. Auf Bitten unserer Partnerorganisation vor Ort, Rotes Kreuz Vrancea, finanzierte der Hilfsverein Nymphenburg mit Hilfe des Georgsritter Ordens den Kauf von 15 Wohncontainern, damit Familien mit Kleinkindern und Alten wieder ein Dach über dem Kopf hatten. Die Gemeinde Focsani stellte das benötigte Gelände am Stadtrand zur Verfügung und sagte zu, für Wasser und Strom zu sorgen. Inzwischen bewohnen die Familien die Container und der Schulbesuch der Kinder ist gesichert. Die Stiftung unterstützt insgesamt drei Roma- Kinderclubs des Roten Kreuzes. 
                `,
                strImgPath: "romania/4.jpeg"
             },
             {       
              strTitle: "Altenhilfe",
                txtBody:
                `
                Rumänien leidet unter der starken Arbeitsmigration der jungen Bevölkerung ins Ausland, die unter anderem zu einem Mangel an Pflegekräfte führt. Viele alte Menschen sind alleine und benötigen einen Platz in einem Altersheim, doch das Angebot ist viel zu gering und die staatliche Sozialhilfe reicht nicht aus. Der Hilfsverein Nymphenburg unterstützt die Altenhilfe des Roten Kreuzes Vrancea und die Casa Bavareza sowie das Haus St. Georg in Vartescoiu wurden wesentlich vom Georgsritter Orden finanziert. 
                `,
                strImgPath: "romania/8.jpeg"
             },
             {
                strTitle: "Sozialküche Bukarest",
                txtBody:
                `
                Seit vielen Jahren unterstützen wir außerdem die Sozialküche der Caritas Bukarest mit Lebensmitteln und das Caritas Waisenhaus St. Johannes, in dem viele alleingelassene Kinder Zuflucht finden. Im Ort Panaci, inmitten der Karpaten gelegen, unterstützt der Hilfsverein Nymphenburg hier mit Hilfe von Frau Acatrinei arme Familien. Rund um die Großstadt Suceava werden in Radauti die Sozialküche und in Dumbraveni das Altenheim von Pfarrer Jaba von uns mitfinanziert.
Im letzten Jahr haben wir zudem unsere Partnerorganisationen in Rumänien bei der Aufnahme von Geflüchteten aus der Ukraine mit der Bereitstellung von Lebensmitteln, Hygieneartikeln, Schlafsäcken und anderen Bedarfsgütern unterstützt, unter anderem die Caritas Bukarest.
                `,
                strImgPath: "romania/3.jpg"
              },       
              {       
                strTitle: "Hilfe wo nötig",
                txtBody:
                `
                Seit zwölf Jahren kommt Doktor Catalina Candea wöchentlich zur Medizinstation, welche der Hilfsverein Nymphenburg in Marasesti gebaut hat. Für ca. 1600 Personen der Roma-Community stellen wir Medikamente und medizinische Versorgung zur Verfügung. Die Sanitärräume stehen den Bewohnern von Marasesti offen. Mittlerweile finden sie auch vermehrt Zuspruch von der stets größer werdenden illegalen „Siedlung“ nebenan, die ohne einen regulären Wasseranschuss ist.
                `,
                strImgPath: "romania/6.jpeg"
              },

            
             
         ]

       }

   }
   
}
</script>
