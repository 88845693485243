<template>

  <div class="mt-15">

    <v-parallax
              :height="ParallaxHeight"
              src="../../public/img/flagsonly.jpg"
    >
      <div class="mx-auto parallax-title" :style="ParallaxTitle">
                  UPDATES
      </div>
            
      </v-parallax>
    <v-layout align-center justify-center>

      
               
      <v-card flat min-width="300" max-width="660" class="px-8 mt-3">
        
        Auf dieser Seite finden Sie aktuelle Updates zu unserer Hilfsaktion Ukraine. Da wir mit einem kleinen Team arbeiten, entschuldigen Sie bitte, dass wir hier nicht tagesaktuell neue Informationen einstellen können. 
        

        <v-card class="pa-3 mt-3 mb-3">
          <v-card-title>
            30 März 2022: <br>
            Sachspenden aus Bayern sind heute angekommen
          </v-card-title>
          <img src="../../public/img/collection.jpg" :width="ImageSize" class="section-title" />
          <br><br>
          Die ersten 16 Paletten an Sachspenden aus Bayern sind heute in Siret angekommen. Dies sind Güter die in unseren Sammelstellen bei der Porzellanmanufaktur Nymphenburg, Gut Waldersteig und bei „Unser Land „ abgegeben wurden. Wir danken allen Spendern und den Freiwilligen die bei der Sammelaktion mitgemacht haben. Die Sachspenden werden nun in unserem Larger neu sortiert und gehen dann unmittelbar an verschiedene Orte in der Ukraine. 
        </v-card>
        
        <v-card class="pa-3 mt-3 mb-3">
          <v-card-title>
            26 März 2022: <br>
            Generatoren für das Hinterland
          </v-card-title>
          <img src="../../public/img/generators.jpg" :width="ImageSize" class="section-title" />
          <br><br>
          Immer öfter werden kleine Generatoren angefragt, um in den Gebieten die von der Stromversorgung abgeschnitten ist wenigstens mit dem nötigsten Strom zu versorgen. Der Hilfsverein konnte 50 Generatoren in Rumänien erwerben und die ersten gingen mit anderen Hilfsgütern heute in die Ukraine und werden über das dortige Rote Kreuz in den Gebieten Lviv, Ternopil und Mikolaev verteilt. 
        </v-card>
        <v-card class="pa-3 mt-3 mb-3">
          <v-card-title>
            22 März 2022: <br>
            Binnen Geflüchtete in Czernowitz
          </v-card-title>
          <img src="../../public/img/displaced.jpg" :width="ImageSize" class="section-title" />
          <br><br>
          In Czeronwitz sind inzwischen ca 80.000 Binnen geflohene untergebracht. Diese ind zum Beispiel in Universitäten Schulen und anderen öffentlichen Plätzen notweinquartiert. Die Versorgung ist viel problemreicher als in den recht gut organisierten Nachbarländern. Vor allem Nahrung und Hygine wird gebraucht. Auch diese Woche wurden Hilfsgüter im Wert von ca 25 TEUR in Rumänien erworben und zum Großteil dorthin geliefert.   
        </v-card>
        <v-card class="pa-3 mt-3 mb-3">
          <v-card-title>
            18 März 2022: <br>
            Hilfsgüter angekommen
          </v-card-title>
          <img src="../../public/img/chernivtsi.jpg" :width="ImageSize" class="section-title" />
          <br><br>
          Die Groß Lieferung des Hilfsverein in Zusammenarbeit mit dem rumänischen Roten Kreuz wurde dankbar in Czernowitz entgegengenommen. Von dort gehen die Güter in andere Landesteile der Ukraine. Vor allem die Medizingüter werden dringend in Krankenhäusern in Kiew und Charkiw benötigt, wir sind derzeit dabei noch mehr Medizinische Ausrüstung in Rumänien zu erwerben. 
        </v-card>
        <v-card class="pa-3 mt-3 mb-3">
          <v-card-title>
            17 März 2022: <br>
            Großtransport in die Ukraine
          </v-card-title>
          <img src="../../public/img/transport.jpg" :width="ImageSize" class="section-title" />
          <br><br>
            Heute geht ein großer Transport-Konvoi des rumänischen Roten Kreuzes von Siret über die Grenze. Er ist fast ausschließlich vom Hilfsverein ausgestattet, u.a. mit medizinischen Mitteln im Wert von ca. 30 TEUR und Nahrung im Wert von ca. 40 TEUR<br>
        </v-card>
        <v-card class="pa-3 mt-3 mb-3">
          <v-card-title>
            16 März 2022: <br>
            Annahme Sachspenden
          </v-card-title>
          <img src="../../public/img/manufaktur.jpg" :width="ImageSize" class="section-title" />
          <br><br>
            Seit heute werden an der Porzellanmanufaktur Nymphenburg auch Sachspenden angenommen. Schon nach dem ersten Tag wurden ca 12 Tonnen an Sachspenden abgegeben. Wir bitten aber uns vor der Abgabe zu kontaktieren, um sicherzustellen, dass die Dinge noch gebraucht werden. 
          <br>
        </v-card>
        <v-card class="pa-3 mt-3 mb-3">
          <v-card-title>
            14 März 2022: <br>
            Larger an Grenze füllt sich
          </v-card-title>
          <img src="../../public/img/larger.jpg" :width="ImageSize" class="section-title" />
          <br><br>
            Dank den eingehenden Spenden können nun in großen Umfang Lebensmittel und medizinische Güter in Rumänien gekauft und direkt an die Grenze gebracht werden. Von dort aus übernimmt sie dann das ukrainische Rote Kreuz. 
          <br>
        </v-card>
        <v-card class="pa-3 mt-3 mb-3">
          <v-card-title>
            12 März 2022: <br>
            ca 2000 Briefe verschickt
          </v-card-title>
          <img src="../../public/img/pack.jpg" :width="ImageSize" class="section-title" />
          <br><br>
          Da inzwischen die Informationslage klar genug ist, dass über Rumänien in der Ukraine geholfen werden kann, wurde vom Hilfsverein ein groß angelegter Spendenaufruf gestartet. Dazu wurden in Schloss Nymphenburg ca. 2000 Briefe adressiert. Auch der Hausritterorden St. Georg und der Alphazirkel schlossen sich dem gemeinsamen Spendenaufruf an. 
          <br>
        </v-card>
        <v-card class="pa-3 mt-3 mb-3">
          <v-card-title>
            9 März 2022: <br>
            Heizpilze für den Grenzübergang. 
          </v-card-title>
          <img src="../../public/img/heater.jpg" :width="ImageSize" class="section-title" />
          <br><br>
          Während die Situation am Grenzübergang auf rumänischer Seite gut geregelt ist, müssen Mütter mit ihren Kindern auf ukrainischer Seite stundenlang in eisiger Kälte anstehen. Um die Situation zu verbessern, erwarb der Hilfsverein 10 Heizpilze und Gasflaschen, die an die ukrainischen Grenzbeamten übergeben wurden.
          <br>
        </v-card>
        <v-card class="pa-3 mt-3 mb-3">
          <v-card-title>
            8 März 2022: <br>
            Erster Hilfstransport
          </v-card-title>
          <img src="../../public/img/rk_hv.jpg" :width="ImageSize" class="section-title" />
          <br><br>
          Ein erster Hilfstransport mit Gütern, zur Hälfte vom Hilfsverein und zur anderen Hälfte aus rumänischen Spenden, ging als Test über die Grenze. Dieser erreichte gut Czernowitz. Dort wurde eine Vereinbarung mit dem ukrainischen Roten Kreuz geschlossen, die größere Transporte bis ins Landesinnere ermöglicht.
          <br>
        </v-card>
        <v-card class="pa-3 mt-3 mb-3">
          <v-card-title>
            7 März 2022: <br>
            Kommunikation Sicherstellen. 
          </v-card-title>
          <img src="../../public/img/thuraya.jpg" :width="ImageSize" class="section-title" />
          <br><br>
         Damit die Kommunikation in die Ukraine auch im Fall einer Attacke auf das Telefonnetz nicht abbricht, wurden die wichtigsten Ansprechpartner des Hilfsverein bereits mit Satellitentelefonen ausgestattet: zum Beispiel die Lagerverwalter des ukrainischen Roten Kreuzes. 
          <br>
        </v-card>
        <v-card class="pa-3 mt-3 mb-3">
          <v-card-title>
            6 März 2022: <br>
            Projektreise Siret
          </v-card-title>
          <img src="../../public/img/office.jpg" :width="ImageSize" class="section-title" />
          <br><br>
          Über das Netzwerk des Hilfsvereins wurde eine Lagerhalle mit eigener Logistik direkt am Grenzposten Siret, zwischen Rumänien und der Ukraine zur Verfügung gestellt. Gemeinsam mit Marsin Agricultura und dem Rumänischen Roten Kreuz hat der Hilfsverein dort nun seit dem 7. März eine ständige Präsenz.
          <br>
        </v-card>
        <v-card class="pa-3 mt-3 mb-3">
          <v-card-title>
            5 März 2022: <br>
            Partner Bukowina
          </v-card-title>
          <img src="../../public/img/dumbraveni.jpg" :width="ImageSize" class="section-title" />
          <br><br>
          Als erste Maßnahme wurde den langjährigen Partnerorganisationen des Hilfsvereins in der Bukowina eine Soforthilfe von insgesamt 10.000 EUR zur Verfügung gestellt, um sich auf den Ansturm der Geflüchteten vorzubereiten, Unter anderem zum Beispiel dem orthodoxen Priester Jabba im Altenheim „Herzog Franz“ in Dumraveni. Dort wurden 40 Betten bereitgestellt. Weitere Partner des Hilfsvereins bereiten warmes Essen für die Geflüchteten an der Grenze zu. 
          <br>
        </v-card>
        <v-card class="pa-3 mt-3 mb-3">
          <v-card-title>
            4 März 2022: <br>
            Beginn Hilfsaktion Ukraine
          </v-card-title>
          <img src="../../public/img/map.png" :width="ImageSize" class="section-title" />
          <br><br>
          Abgestimmt mit dem Stiftungsrat, beschliesst der Vorstand des Hilfsverein ein Sofort Hilfepaket für die Ukraine.
          Zunächst werden 50 TEUR freigegeben um schnell und felxibel auf anfragen reagieren zu können. 
          Eine Projektreise soll so bald wie möglich stattfinden. 
          <br>
        </v-card>
        

      </v-card>

      

    </v-layout>
  </div>
</template>


<script>
// @ is an alias to /src

export default {
  name: 'Items',
  components: {
  },
  computed: {
    TitleSize () {
            switch (this.$vuetify.breakpoint.name) {
            case 'xs': return 'font-size:20px'
            case 'sm': return 'font-size:25px'
            case 'md': return 'font-size:30px'
            case 'lg': return 'font-size:40px;'
            case 'xl': return 'font-size:50px;'
            default: return 'font-size:25px'

            }
        },
   
        IconSize () {
            switch (this.$vuetify.breakpoint.name) {
            case 'xs': return '60px'
            case 'sm': return '75px'
            case 'md': return '90px'
            case 'lg': return '120px;'
            case 'xl': return '150px;'
            default: return '25px'

            }
        },
        
        ImageSize () {
            switch (this.$vuetify.breakpoint.name) {
            case 'xs': return '300px'
            case 'sm': return '400px'
            case 'md': return '500px'
            case 'lg': return '500px;'
            case 'xl': return '500px;'
            default: return '25px'

            }
        },
        ParallaxHeight () {
            switch (this.$vuetify.breakpoint.name) {
            case 'xs': return '300'
            case 'sm': return '400'
            case 'md': return '500'
            case 'lg': return '700'
            case 'xl': return '800'
            default: return '400'

            }
        },
        ParallaxTitle () {
            switch (this.$vuetify.breakpoint.name) {
            case 'xs': return 'font-size:40px; color:rgb(40,53,130); text-align: center; text-align: center;'
            case 'sm': return 'font-size:60px; color:rgb(40,53,130); font-weight: bold; text-align: center;'
            case 'md': return 'font-size:80px; color:rgb(40,53,130); font-weight: bold; text-align: center;'
            case 'lg': return 'font-size:140px; color:rgb(40,53,130); font-weight: bold; text-align: center;'
            case 'xl': return 'font-size:200px; color:rgb(40,53,130); font-weight: bold; text-align: center;'
            default: return 'font-size:40px; color:rgb(40,53,130); font-weight: bold;'

            }
        },
        
        
  }
}
</script>
