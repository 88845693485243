<template>
    <div>
        <v-card class="mt-15">
            <v-card-title>
                <div style="color: #283583">{{ strTitle }}</div>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="3">
                        <v-img 
                            :src="ImagePath(strImgPath)"
                            max-width=300
                            max-height=300
                        ></v-img>

                    </v-col>
                    <v-col class="ml-5 mt-5">
                        <v-row style="font-size: 20px;">
                            {{ txtBody }}
                        </v-row>
                        <v-row>
                            <v-btn class="mt-5" color="primary darken-3" @click="CleverRoute('donate/dedication/' + strTitle)">
                                Spenden
                            </v-btn>
                        </v-row>
                        
                    </v-col>


                </v-row>
        
                
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
    props: {
        strTitle: {
            type: String,
            default: ""
        },
        txtBody: {
            type: String,
            default: ""
        },
        strImgPath: {
            type: String,
            default: "../../public/img/Nymphenburg_long.png"
        }
        
    },
    data: function(){
        return{

        }

    },
    methods:{
        ImagePath: function(strImageName){
            var strFileName =  strImageName;
            //var pth = process.env.VUE_APP_IMG_PATH;
            return '/img/' + strFileName;
        },
        
        CleverRoute: function (strRoute) {
            console.log(strRoute)
            if (!window.location.href.includes("reset")) {
                this.$router.push({ path: strRoute });
            } else {
                this.$router.push({ path: "." + strRoute });
            }
        },

    }
    
}
</script>