<template>
    <div>
        <v-card class="mt-15">
            
            <v-card-text>
                <v-row>
                    <v-col cols="4">
                        <v-img 
                            :src="ImagePath(strImgPath)"
                            max-width=400
                            max-height=400
                        ></v-img>

                    </v-col>
                    <v-col class="ml-5">
                        <v-row>
                            <div style="color: #283583; font-size: 22px;">{{ strTitle }}</div>
                            
                        </v-row>
                        <v-row  class="mt-6" style=" font-size: 18px;">
                            {{ txtBody }}
                        </v-row>
                        
                    </v-col>


                </v-row>
        
                
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
    props: {
        strTitle: {
            type: String,
            default: ""
        },
        txtBody: {
            type: String,
            default: ""
        },
        strImgPath: {
            type: String,
            default: "../../public/img/Nymphenburg_long.png"
        }
        
    },
    data: function(){
        return{

        }

    },
    methods:{
        ImagePath: function(strImageName){
            var strFileName =  strImageName;
            //var pth = process.env.VUE_APP_IMG_PATH;
            return '/img/projects/' + strFileName;
        }
    }
    
}
</script>